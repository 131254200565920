import styled from "styled-components";
import {useIntl} from "react-intl";
import {useState} from "react";


function Privacy() {

  const { formatMessage, locale } = useIntl();

  const fm = id => formatMessage({id});

  return (
    <Container>
      <div className='title'>{fm('Privacy Policy')}</div>

      <div className='content'>
        {
          locale === 'en' ? <>
            {fm('The Nguyễn-Phương Family recognizes the importance of protecting your personal and financial information when you visit our website located at       https://www.nguyenphuongfamily.org/ (“Website“). The following information is designed to help you understand the information collection practices at this Website.')}
          </> : <>
            Gia đình Nguyễn-Phương nhận thấy tầm quan trọng của việc bảo vệ thông tin cá nhân và tài chính của bạn khi bạn truy cập trang web của chúng tôi tại https://www.nguyenphuongfamily.org/ (“Trang web“). Thông tin sau đây được thiết kế để giúp bạn hiểu các hoạt động thu thập thông tin tại Trang web này.
          </>
        }

        <p></p>
        {fm('By visiting this Website, you are accepting the practices described in this Privacy Policy. If you do not agree to this policy, please do not use this Website. Please review the terms and conditions on the Website to learn of other terms and conditions applicable to your use of this Website.')}

        <div className='sub-title sub-title-special'>
          {fm('Information Collection and Use')}
        </div>

        <div className='sub-title'>
          {fm('Methods of Information Collection')}
        </div>

        <div>
          {fm('We may receive personally identifiable information you submit to or through our Website. By disclosing your personally identifiable information to us via our Website, you consent to our collection, use and disclosure of your personally identifiable information for the purposes as set out in this Privacy Policy. Failure to provide such personally identifiable information may result in the Nguyễn-Phương Family being unable to provide you with the services or information requested.')}
        </div>

        <div className='sub-title'>{fm('Information Collected and Purpose of Information Collection')}</div>


        <div>
          {fm('The types of personally identifiable information that may be collected at our Website include information you provided to us through the “Contact Us” page (which may include your name and email address).')}
        </div>


        <div className='sub-title'>{fm('Use of Personally Identifiable Information')}</div>

        <div>
          {
            locale === 'en' ? <>
              {fm('The personally identifiable information you submit to or through our Website is used only for the following purposes:')}
              <ul>
                <li>{fm('Responding to your inquiry sent to us; and')}</li>
                <li>{fm('Other directly related purposes.')}</li>
              </ul>
              <p></p>
              {fm('We will not sell, share, rent or otherwise disclose or transfer your personally identifiable information to others without your consent.')}
            </> : <>
              Thông tin nhận dạng cá nhân bạn gửi đến hoặc thông qua Trang web của chúng tôi chỉ được sử dụng cho các mục đích sau:
              <ul>
                <li>Trả lời yêu cầu của bạn gửi cho chúng tôi; </li>
                <li>Và Các mục đích liên quan trực tiếp khác. </li>
              </ul>
              <p></p>
              Chúng tôi sẽ không bán, chia sẻ, cho thuê hoặc tiết lộ hay chuyển giao thông tin nhận dạng cá nhân của bạn cho người khác mà không có sự đồng ý của bạn.
            </>
          }

        </div>

        <div className='sub-title'>{fm('Retention Period')}</div>

        <div>
          {fm('We will take practicable steps to ensure your personally identifiable information will not be retained by us longer than is necessary for the fulfillment of the purposes.')}
        </div>
      </div>
    </Container>
  )
}

const Container = styled.div`
  ul {
    padding-left: 30px;

    @media (max-width: 991px) {
      padding-left: 20px;
    }
  }
  
  .sub-title-special {
    text-decoration-line: underline;
  }
  
  .sub-title {

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin: 20px 0;
    @media (max-width: 991px) {
      font-size: 11px;
      margin: 10px 0;
    }
  }
  
  .content {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;

    @media (max-width: 991px) {
      font-size: 11px;
    }
    
    & > p {
      margin-bottom: 20px;
    }
  }
  .title {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    color: #000000;

    @media (max-width: 991px) {
      font-size: 24px;
    }
  }
  
  width: 85%;
  margin: 100px auto 100px auto;

  @media (max-width: 991px) {
    margin: 20px auto;
  }
`

export default Privacy;
