import React, { useState, useEffect, useRef } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import importAllImg from "../helpers/import-all-imgs";
import Footer from './Footer';
import Header from './Header';
import { useIntl } from 'react-intl';
import { useNavigate } from "react-router-dom";
import {Carousel} from 'antd';


const imgs = importAllImg(require.context('../img/npf', false, /\.(png|jpe?g|svg)$/));

function Home() {

  const navigate = useNavigate();
  const { formatMessage, locale } = useIntl();
  const ref = useRef();
  const [tab, setTab] = useState(0);

  const fm = id => formatMessage({id});

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const isMobile = window.innerWidth <= 991;
  return (
    <Container>
      <div>

        <div className='top-video-container' >
          <video
            src='https://file.udty.tech/20240527/1716781820951_NPFamily%20Hero%20video.mp4'
            autoPlay muted loop playsInline>
          </video>
          {/*<img className='top-img' src={locale === 'vi' ? imgs['172528391-2bfa13f3-bfa9-4536-b071-6c3b6ec2a749.png'] : imgs['Group 1932.svg']} />*/}
          {/*<div className='top-text' data-aos='fade-up'>*/}
          {/*  {fm('SUPPORTING CHANGEMAKERS TO BUILD A BETTER WORLD')}*/}
          {/*</div>*/}
        </div>


        <div className='middle-panel'>
          <img className='middle-img' src={imgs['pexels-braven-nguyen-1173174-13994803.png']} />
          <div className='middle-panel-content'>
            <div className='our-mission'>
              <div>{fm('OUR VISION')}</div>
              <div>
                {
                  fm('Inspired by interconnectedness, the Nguyễn-Phương Family believes every person deserves to live with dignity. Where every human being has the same opportunity to live and thrive. We aim to leave the world better than how we found it.')
                }
              </div>
            </div>
            <img className='mobile-img' src={imgs['mountain-6576362_1280 (1).png']} />
            <div className='our-approach'>
              <div>{fm('OUR APPROACH')}</div>
              {
                locale === 'en' ?
                  <div>
                    We are living in a time of unprecedented global challenges. <br/>
                    We need a new <span>Radical Philanthropy</span> to create meaningful change. <br/>
                    <p></p>
                    The Nguyễn-Phương Family supports organizations and Changemakers that enhance the wellbeing of humanity. We do this through investment, collaborations and resource sharing. We are strong believers in promoting inner transformation, systems change and patient capital. Our philanthropic work is currently focused across on South East Asia.
                  </div>
                  :
                  <div>
                    Chúng ta đang sống trong thời kỳ có những thách thức toàn cầu chưa từng có.
                    Chúng ta cần một tổ chức<span>từ thiện cấp tiến</span> mới để tạo ra sự thay đổi có ý nghĩa. <br/>
                    <p></p>

                    Gia đình Nguyễn-Phương hỗ trợ các tổ chức và Người tạo ra sự thay đổi nhằm nâng cao phúc lợi của nhân loại. Chúng tôi thực hiện điều này thông qua đầu tư, hợp tác và chia sẻ tài nguyên. Chúng tôi rất tin tưởng vào việc thúc đẩy chuyển đổi nội tâm, thay đổi hệ thống và vốn kiên nhẫn. Công việc từ thiện của chúng tôi hiện đang tập trung trên khu vực Đông Nam Á.
                  </div>
              }
            </div>
          </div>
        </div>



        <div className='focus-container'>
          <div className='focus-area'>
            <div className='focus-title'>{fm('OUR FOCUS AREAS')}</div>
            <div className='focus-text'>{fm('We focus on the following pillars to build a better and more just world:')}</div>
          </div>

          <div className='focus-switch'>
            <div className='switch-tabs'>
              <span className={tab === 0 ? 'selected': ''} onClick={() => setTab(0)}>{fm('LIVELIHOOD')}</span>
              <span className={tab === 1 ? 'selected': ''} onClick={() => setTab(1)}>{fm('INNER CHANGE')}</span>
              <span className={tab === 2 ? 'selected': ''} onClick={() => setTab(2)}>systems change</span>
            </div>

            <div >
              <div className='switch-content' style={{display: tab === 0 ? '' : 'none'}}>
                <div className='switch-left-panel'>
                  <div className='switch-content-title'>{fm('LIVELIHOOD')}</div>
                  <div className='switch-content-text'>
                    {fm('Every person needs to live with dignity and equal opportunity. With livelihood, we cover the following areas:')}
                  </div>
                  <ul className='switch-content-ul'>
                    <li>
                      <img src={imgs['Book Bookmark.svg']} />
                      {fm('EDUCATION FOR ALL').toLowerCase()}
                    </li>
                    <li>
                      <img src={imgs['Hand Heart.svg']} />
                      {fm('POVERTY ALLEVIATION').toLowerCase()}
                    </li>
                    <li>
                      <span className='for-img-span'>
                        <img src={imgs['pure-natural.svg']} />
                      </span>
                      {fm('Environmental Sustainability')}
                    </li>
                  </ul>
                  <div className='switch-content-btn' onClick={() => navigate('/livelihood')}>{fm('Learn more')}</div>
                </div>
                <div className='switch-righ-panel'>
                  <img src={imgs['mario-heller-hXLkFpvKRys-unsplash 9 (1).png']} />
                </div>
              </div>

              <div className='switch-content' style={{display: tab === 1 ? '' : 'none'}}>
                <div className='switch-left-panel'>
                  <div className='switch-content-title'>{fm('INNER CHANGE')}</div>
                  <div className='switch-content-text'>
                    {fm('Without changing oneself, we cannot create positive impact in the world. Every person should find their purpose and fulfill their potential. With inner change, we cover the following areas:')}
                  </div>
                  <ul className='switch-content-ul'>
                    <li>
                      <img src={imgs['Meditation.svg']} />
                      {fm('Mindfulness')}</li>
                    <li>
                      <img src={imgs['Heart Pulse.svg']} />
                      {fm('Moral Leadership')}</li>
                  </ul>
                  <div className='switch-content-btn' onClick={() => navigate('/inner-change')}>{fm('Learn more')}</div>
                </div>
                <div className='switch-righ-panel'>
                  <img src={imgs['pexels-prasanth-inturi-1051838 7.png']} />
                </div>
              </div>

              <div className='switch-content' style={{display: tab === 2 ? '' : 'none'}}>
                <div className='switch-left-panel'>
                  <div className='switch-content-title'>systems change</div>
                  <div className='switch-content-text'>
                    {fm('To truly address our societal problems, we need to address causes, rather than symptoms . With systems change, we cover the following areas:')}
                  </div>
                  <ul className='switch-content-ul'>
                    <li>
                      <span className='for-img-span'>
                        <img src={imgs['puzzle.svg']} />
                      </span>
                      {fm('Social Entrepreneurship')}</li>
                    <li>
                      <img src={imgs['Link Round.svg']} />
                      {fm('Collaboratives')}</li>
                  </ul>
                  <div className='switch-content-btn' onClick={() => navigate('/systems-change')}>{fm('Learn more')}</div>
                </div>
                <div className='switch-righ-panel'>
                  <img src={imgs['pexels-porapak-apichodilok-346885 7.png']} />
                </div>
              </div>
            </div>
          </div>

        </div>


        <div className='focus-area'>
          <div className='focus-title'>
            {fm('our affiliations')}
          </div>
          <div className='focus-text'>{fm('We are affiliated with the following networks:')}</div>
        </div>

        <div className='following-grid'>
          <div className='following-content'>
            <div>
              <img  className='special' src={false ? imgs['image 2169 (1).svg']: imgs['image 2200.svg']} />
            </div>
            <div>
              {fm('APC is a membership platform for Asian philanthropists to jointly grow the impact of their philanthropy and to catalyse an Asian philanthropy.')}
              <span onClick={() => window.open('https://asiaphilanthropycircle.org/')} className='learn-more'><img src={imgs['arrow-down-right-svgrepo-com 1.svg']} /></span>
            </div>
          </div>

          <div className='following-content' style={{background: '#354402'}}>
            <div>
              <img  className='special' src={false? imgs['image 2170 (1).svg']: imgs['image 2206.svg']} />
            </div>
            <div>
              {fm('Nexus aims to educate, connect, inspire, and activate the next generation of exceptional social innovators, philanthropists and impact investors globally.')}
              <span onClick={() => window.open('https://nexusglobal.org/')} className='learn-more'><img src={imgs['arrow-down-right-svgrepo-com 1.svg']} /></span>
            </div>
          </div>

          <div className='following-content' style={{background: '#1A377D'}}>
            <div>
              <img className='special' src={false ? imgs['image 2171 (1).svg'] : imgs['legacyventure 1.svg']} />
            </div>
            <div>
              {fm('Legacy Venture is a Fund of Venture Capital funds that manages investment funds. Their clients are investors who direct all investment proceeds, both principal and gains, to charitable organizations.')}
              <span onClick={() => window.open('https://www.legacyventure.com/')} className='learn-more'><img src={imgs['arrow-down-right-svgrepo-com 1.svg']} /></span>
            </div>
          </div>

          <div className='following-content' style={{background: '#091F40'}}>
            <div>
              <img src={imgs['image 2207.svg']} />
            </div>
            <div>
              {fm('TBN Asia is an ecosystem that brings together social entrepreneurs, impact investors, domain experts and others to support and resource sustainable social impact businesses in Southeast Asia.')}
              <span onClick={() => window.open('https://www.tbn.asia/')} className='learn-more'><img src={imgs['arrow-down-right-svgrepo-com 1.svg']} /></span>
            </div>
          </div>


          <div className='following-content' style={{background: '#02256D'}}>
            <div>
              <img src={imgs['image 2208.svg']} />
            </div>
            <div>
              {fm('Rotary is a global network of 1.4 million neighbors, friends, leaders, and problem-solvers who see a world where people unite and take action to create lasting change – across the globe, in our communities, and in ourselves.')}
              <span onClick={() => window.open('https://www.rotary.org/en')} className='learn-more'><img src={imgs['arrow-down-right-svgrepo-com 1.svg']} /></span>
            </div>
          </div>

          <div className='following-content' style={{background: '#00283E'}}>
            <div>
              <img src={imgs['image 2209.svg']} />
            </div>
            <div>
              {fm('Catalyst 2030 is a fast-growing global movement of people and organisations committed to advancing the UN Sustainable Development Goals (SDGS) by 2030.')}
              <span onClick={() => window.open('https://catalyst2030.net/')} className='learn-more'><img src={imgs['arrow-down-right-svgrepo-com 1.svg']} /></span>
            </div>
          </div>

        </div>

        <div className='video-panel'>
          <div className='border-panel'></div>
          <div className='video-container'>
            <VideoComponent />
            {/*<video controls autoPlay={false} muted className='video' src='https://file.udty.tech/20240527/1716781766084_Intro%20Video%20Main.mp4'/>*/}
          </div>
        </div>

        <div className='talk-panel'>
          <div>
            {fm('“If you want to awaken all of humanity, then awaken all of yourself. If you want to eliminate the suffering in the world, then eliminate all that is dark and negative in yourself. Truly, the greatest gift you have to give is that of your own self-transformation.”')}
          </div>
        </div>

      </div>
    </Container>
  );
}

const VideoComponent = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const handleIntersection = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          videoRef.current.play();
        } else {
          videoRef.current.pause();
        }
      });
    };

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  return (
    <video
      controls
      ref={videoRef}
      muted
      className='video'
      src='https://file.udty.tech/20240527/1716781766084_Intro%20Video%20Main.mp4'
    />
  );
};

const Container = styled.div`
  .for-img-span {
    display: inline-flex;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
  }
  margin: 0 auto;
  
  .top-video-container {
    margin-bottom: -5px;
    video {
      width: 100%;
    }
  }
  
  .mobile-img {
    display: none;
    @media (max-width: 991px) {
      display: block;
      width: 100%;
    }
  }
  
  .focus-switch {
    display: flex;
    margin: 0 7.5%;
    & > div:last-child {
      flex: 1;
    }
    @media (max-width: 991px) {
      flex-flow: column;
    }
  }
  
  .middle-panel-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    @media (max-width: 991px) {
      position: static;
    }
  }
  
  .middle-panel {
    position: relative;
  }
  
  .middle-img {
    width: 100%;
    height: 1440px;
    object-fit: cover;
    @media (max-width: 991px) {
      display: none;
    }
  }
  
  
  .switch-righ-panel {
    //min-width: 600px;
    //height: 428px;
    //border: 5px solid #354402;
    padding: 35px 0;
    position: absolute;
    top: -100px;
    right: -50px;
    @media (max-width: 991px) {
      min-width: 200px;
      height: 130px;
      border-width: 2px;
      top: -50px;
      right: -65px;
      //transform: translateX(80px);
      & > img {
        width: 126px;
      }
    }
    //& > img {
    //  min-width: 620px;
    //  height: 360px;
    //  margin-left: -50px;
    //  @media (max-width: 991px) {
    //    min-width: 200px;
    //    height: 115px;
    //    margin-left:0;
    //    margin-left: -20px;
    //    margin-top: -30px;
    //  }
    //}
  }
  
  .switch-content-btn {
    color: #FFF;
    font-family: 'Open Sans';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    width: 190px;
    height: 50px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: #354402;
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    @media (max-width: 991px) {
      width: 130px;
      height: 30px;
      font-size: 11px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  
  .switch-content-ul {
    color: #000;
    font-family: 'Open Sans';
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 40px;
    padding-left: 0;
    list-style: none;
    @media (max-width: 991px) {
      font-size: 12px;
      //margin-left: -10px;
      margin-bottom: 15px;
    }
    & > li {
      margin-bottom: 5px;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      column-gap: 10px;

      @media (max-width: 991px) {
        & > span {
          height: auto;
        }
        & > img, & > span {
          width: 20px;
          
          & > img {
            width: 100%;
          }
        }
      }
    }
  }
  
  .switch-content-text {
    color: #000;
    font-family: 'Open Sans';
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 20px;
    width: 60%;
    @media (max-width: 991px) {
      font-size: 12px;
      margin-bottom: 10px;
    }
  }
  
  .switch-content-title {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 5px;
    font-size: 36px;
    color: #354402;

    @media (max-width: 991px) {
      font-size: 13px;
    }
  }
  .switch-content {
    //display: flex;
    //justify-content: space-between;
    padding: 60px 50px;
    padding-left: 10%;
    background: #F3F7E6;
    border-radius: 25px;
    position: relative;
    @media (max-width: 991px) {
      padding: 15px 10%;
    }
  }
  .switch-tabs {
    color: #BBC1A2;
    min-width: 25%;
    display: flex;
    flex-flow: column;
    font-family: 'Bebas Neue';
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    //margin: 60px auto;
    //margin-top: -50px;
    @media (max-width: 991px) {
      margin: 20px auto;
      font-size: 14px;
      width: 90%;
      flex-flow: row;
      justify-content: center;
    }
    & > span {
      cursor: pointer;
      display: inline-block;
      padding: 20px;
      border-left: 20px solid #BBC1A2;
      @media (max-width: 991px) {
        padding-bottom: 1px;
        border-bottom-width: 1px;
        border-left: none;
        border-top: 5px solid #BBC1A2;
        padding: 10px;
      }
      &.selected {
        color: #354402;
        border-color: #354402;
      }
    }
  }
  .our-mission {
    background: #354402;
    height: 720px;
    //width: 720px;
    width: 51%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    padding: 50px;
    padding-left: 80px;
    padding-top: 0;
    margin-left: auto;
    font-size: 24px;
    

    border-radius: 40px 0px 0px 40px;



    @media (max-width: 991px) {
      height: 320px;
      width: 100%;
      border-radius: 0;
      padding: 8%;
    }
    
    & > div:nth-child(1) {
      font-family: 'Bebas Neue';
      font-style: normal;
      font-weight: 400;
      font-size: 36px;
      line-height: 43px;
      color: #FFFFFF;
      margin-bottom: 40px;
      background-image: url("${imgs['Group 2884.svg']}");
      background-size: contain;
      height: 83px;
      display: flex;
      align-items: center;
      //background-position: center;
      background-repeat: no-repeat;

      @media (max-width: 991px) {
        height: 42px;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 20px;
        justify-content: center;
        background-position: center;
      }
      
    }
    
    & > div:nth-child(2) {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      //font-size: 32px;
      line-height: 44px;
      color: #FFFFFF;
      //text-align: center;
      //font-size: 32px;



      @media (max-width: 991px) {
        font-size: 16px;
        line-height: 22px;
        width: 100%;
      }
    }
  }
  
  .our-approach {
    background: #354402;
    height: 720px;
    //width: 720px;
    width: 51%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    padding: 50px;
    //padding-left: 80px;
    padding-top: 0;
    font-size: 24px;

    border-radius: 0px 40px 40px 0px;


    @media (max-width: 991px) {
      height: auto;
      width: 100%;
      border-radius: 0;
      background: #F3F7E6;
      padding: 8%;
      //color: black;
    }

    & > div:nth-child(1) {
      font-family: 'Bebas Neue';
      font-style: normal;
      font-weight: 400;
      font-size: 36px;
      line-height: 43px;
      color: #FFFFFF;
      margin-bottom: 40px;
      background-image: url("${imgs['Group 2884.svg']}");
      background-size: contain;
      height: 83px;
      display: flex;
      align-items: center;
      //background-position: center;
      background-repeat: no-repeat;

      @media (max-width: 991px) {
        height: 42px;
        font-size: 16px;
        margin-bottom: 20px;
        background-position: center;
        justify-content: center;
        color: black;
      }

    }

    & > div:nth-child(2) {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      color: #FFFFFF;
      //text-align: center;

      font-size: 24px;

      color: #FFFFFF;
      
      & > span {
        font-weight: bold;
      }





      @media (max-width: 991px) {
        font-size: 16px;
        width: 100%;
        color: black;
        text-align: center;
      }
    }
  }
  
  .header {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px 0 80px 0;
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;

    color: #11224D;
    
    & > div {
      padding-bottom: 3px;
      border-bottom: 3px solid transparent;
      cursor: pointer;
      &.selected {
        border-color: #FB7E26;
        color: #FB7E26;
      }
    }
    
  }
  
  .support-title {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    font-weight: 400;
    font-size: 50px;
    line-height: 68px;
    color: #000000;
    margin-bottom: 45px;
  }
  
  .support-text {
    width: 50%;
    margin-left: 7.5%;
    font-weight: 400;
    font-size: 30px;
    line-height: 41px;
    color: #000000;
  }
  
  .video-panel {
    position: relative;
    //margin: 62px 0;
    /* Rectangle 2241 */

    background: linear-gradient(180deg, #FFFFFF 0%, #F3F7E6 20%, #F3F7E6 80%, #FFFFFF 100%);
    height: 688px;
    @media (max-width: 991px) {
      margin: 15px 0;
      height: 200px;
    }
  }
  
  .border-panel {
    display: none;
    //border: 5px solid #354402;
    width: 85%;
    height: 688px;
    margin: 0 auto;
    z-index: 1;
    position: relative;
    @media (max-width: 991px) {
      height: 200px;
      border-width: 2px;
    }
  }
  
  .video-container {
    background: white;
    position: absolute;
    z-index: 2;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 634px;
    
    video {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 50px;
      line-height: 68px;
      color: #000000;
      width: 100%;
      height: 100%;
      flex-shrink: 0;

      @media (max-width: 991px) {
        margin-top: 0;
      }
    }
    @media (max-width: 991px) {
      height: 180px;
    }
  }
  
  .video {
    //background: #D9D9D9;
    //height: 750px;

    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 50px;
    line-height: 68px;
    color: #000000;
    width: 100%;
    height: 100%;
    flex-shrink: 0;

    @media (max-width: 991px) {
      margin-top: 0;
    }

  }
  
  .about-us {
    padding: 118px 7.5% 96px 7.5%;
    position: relative;
    background: rgba(251, 126, 38, 0.05);
    @media (max-width: 991px) {
      padding: 42px 5% 36px 5%;
    }
  }
  
  .about-img {
    position: absolute;
    bottom: -30%;
    right: 4%;
    @media (max-width: 991px) {
      width: 78px;
      height: 78px;
      bottom: -17%;
    }
  }
  
  .about-title {
    font-weight: 400;
    font-size: 30px;
    line-height: 41px;
    color: #000000;
    margin-bottom: 30px;
    font-family: 'Bebas Neue';
    @media (max-width: 991px) {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 15px;
    }
  }
  
  .about-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    width: 70%;
    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 19px;
      width: 100%;
    }
  }
  
  .focus-container {
    //background: #F3F7E6;
    @media (max-width: 991px) {
      overflow-x: hidden;
    }
  }
  
  .focus-area {
    width: 85%;
    margin-left: 7.5%;
    padding-top: 52px;
    @media (max-width: 991px) {
      padding-top: 31px;
      width: 90%;
      margin: 0 auto;
    }
  }
  
  .focus-title {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 43px;
    color: #000000;
    background-image: url("${imgs['Group 2884.svg']}");
    background-size: contain;
    height: 83px;
    display: flex;
    align-items: center;
    //justify-content: center;
    //background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 22px;
    @media (max-width: 991px) {
      font-size: 16px;
      line-height: 19px;
      height: 42px;
      margin-bottom: 10px;
      justify-content: center;
      background-position: center;
    }
  }
  
  .focus-text {
    font-weight: 400;
    margin-bottom: 40px;
    //text-align: center;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: #000000;
    line-height: normal;
    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 19px;
      width: 80%;
      margin: 10px auto;
      text-align: center;
    }

  }
  
  .focus-imgs {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    @media (max-width: 991px) {
      display: none;
    }
    & > div {
      width: 48%;
      margin-bottom: 45px;
      & > div:nth-child(1) {
        display: flex;
      }
      & > div:nth-child(2) {
        display: none;
      }
      &:hover {
        & > div:nth-child(1) {
          display: none;
        }
        & > div:nth-child(2) {
          display: flex;
        }
      }
      //& > img {
      //  width: 100%;
      //  height: auto;
      //  cursor: pointer;
      //}
    }

  }
  
  .talk-panel {

    border-radius: 40px 40px 0px 0px;
    margin-bottom: -40px;
    
    height: 392px;
    //width: 50%;
    //margin: 20px auto 80px auto;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    background-color: #354402;
    background-image: url("${imgs['Vector.svg']}");
    background-position: center center;
    background-repeat: no-repeat;
    color: white;

    @media (max-width: 991px) {
      background-size: 235px 235px;
      height: 268px;
      width: 100%;
      //margin: 12px auto 36px auto;
      font-size: 14px;
      line-height: 19px;
      position: relative;
      z-index: 2;
    }
    & > div:nth-child(1) {
      width: 50%;
      margin-bottom: 20px;
      @media (max-width: 991px) {
        width: 70%;
      }
    }
  }
  
  .following-title {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    //font-weight: 400;
    font-size: 30px;
    line-height: 41px;
    color: #000000;
    margin-bottom: 50px;
    font-family: 'Open Sans';

    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 35px;
      margin-top: 30px;
    }
  }
  
  .following-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 30px;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 70px;
    @media (max-width: 991px) {
      grid-template-columns: 1fr;
      margin: 30px auto;
      grid-row-gap: 0;
    }
  }
  
  .following-content {
    display: flex;
    flex-flow: column;
    //justify-content: space-between;
    //align-items: center;
    //width: 400px;
    width: 100%;
    //height: 210px;

    background: #00738E;
    border-radius: 20px;
    padding: 20px;

    @media (max-width: 991px) {
      flex-flow: column;
      align-items: flex-start;
      margin-bottom: 16px;
      width: 100%;
    }
    
    & > div:nth-child(1) {
      height: 73px;
      display: flex;
      align-items: center;
      margin-bottom: 14px;
      & > img {
        //height: 100%;
      }
      @media (max-width: 991px) {
        //width: 165px;
        //justify-content: center;
        width: 100%;
        height: auto;
        & > img {
          height: 40px;
          &.special {
            max-height: 70px;
            height: auto;
          }
        }
        margin-bottom: 8px;
      }

    }
    & > div:nth-child(2) {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #FFFFFF;
      width: 95%;
      flex: 1;

      position: relative;

      & > span {
        //font-weight: 700;
        //text-decoration-line: underline;
        //color: #11224D;
        position: absolute;
        cursor: pointer;
        bottom: -10px;
        right: -5%;
      }
      @media (max-width: 991px) {
        font-size: 14px;
        line-height: 19px;
        width: 100%;
      }
    }
  }
  
  .learn-more {
    text-decoration-line: underline;
    color: #11224D;
    cursor: pointer;
    margin-left: 5px; 
  }
  
  .top-img-container {
    height: 810px;
    background-image: url('${imgs['tree-g5916ce5aa_1920.png']}');
    background-size: cover;
    padding-top: 175px;
    @media (max-width: 991px) {
      height: 211px;
      padding-top: 60px;
    }
  }
  
  .top-img {
    width: 580px;
    height: 200px;
    margin: 250px 0 70px 7.5%;
    @media (max-width: 991px) {
      width: 143px;
      height: 79px;
      margin: 0 34px;
    }
  }
  
  .top-text {
    width: 565px;
    height: 120px;
    margin: 250px 0 70px 7.5%;
    color: #FFF;
    font-family: 'Bebas Neue';
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media (max-width: 991px) {
      width: 143px;
      height: 79px;
      margin: 50px 28px 10px 28px;
      font-size: 16px;
    }
  }
  
  .pa-img {
    
    background-image: url("${imgs['pexels-quang-nguyen-vinh-6711553 4.png']}");
    background-size: cover;
    background-repeat: no-repeat;
    height: 320px;
    width: 100%;
    @media (max-width: 991px) {
      width: 100%;
      height: 186px;
    }
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  
  .pa-img-hover {
    background-image: url("${imgs['Group 1952.png']}");
    background-size: cover;
    background-repeat: no-repeat;
    height: 320px;
    width: 100%;
    
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    
    & > div:nth-child(1) {
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
      color: #FFFFFF;
      padding-bottom: 11px;
      border-bottom: 3px solid #FFFFFF;
    }
    
    & > div:nth-child(2) {
      width: 60%;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      color: #FFFFFF;
      padding-top: 16px;
    }
  }
  
  .pa-img0 {
    background-image: url("${imgs['mario-heller-hXLkFpvKRys-unsplash 1.png']}");
  }

  .pa-img-hover0 {
    background-image: url("${imgs['Group 1953.png']}");
  }

  .pa-img2 {
    background-image: url("${imgs['pexels-prasanth-inturi-1051838 1.png']}");
  }

  .pa-img-hover2 {
    background-image: url("${imgs['Group 1954.png']}");
  }

  .pa-img3 {
    background-image: url("${imgs['pexels-porapak-apichodilok-346885 1.png']}");
  }

  .pa-img-hover3 {
    background-image: url("${imgs['Group 1955.png']}");
  }
  
  .mobile-carousel {
    display: none;
    @media (max-width: 991px) {
      display: block;
      width: 90%;
      margin: 0 auto;
    }
  }
  
  .slick-dots {
    margin-bottom: -30px;
  }
  
  .slick-dots li:not(.slick-active) button {
    width: 4px;
    height: 4px;
    background: rgba(0, 0, 0, 0.12);
    border-radius: 38px;
    
  }

  .slick-dots li.slick-active button {
    background: rgba(0, 0, 0, 0.25);

  }

  
`;

export default Home;

