import React, { useState, useEffect, useRef } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useIntl } from 'react-intl';
import importAllImg from "../helpers/import-all-imgs";

const imgs = importAllImg(require.context('../img/npf', false, /\.(png|jpe?g|svg)$/));


function OurBlog() {

  const { formatMessage } = useIntl();

  const fm = id => formatMessage({id});

  useEffect(() => {
    var container = document.getElementById("retainable-rss-embed");
    if (container) {
      var css = document.createElement('link');
      css.href = "https://www.twilik.com/assets/retainable/rss-embed/retainable.css";
      css.rel = "stylesheet"
      document.getElementsByTagName('head')[0].appendChild(css);
      var script = document.createElement('script');
      script.src = "https://www.twilik.com/assets/retainable/rss-embed/retainable.js";
      document.getElementsByTagName('body')[0].appendChild(script);
    }
  }, [])

  return (
    <Container>
      <div className='top'>
        <div className='title'>{fm('Blog')}</div>
        <div className='des'>
          {fm('Sharing our thoughts, learnings and showcasing ideas from Changemakers to build a better world')}
        </div>
      </div>
      <div className='content'>
        <div>

          <div className='blogs'>
            <div id="retainable-rss-embed"
                 data-rss="https://medium.com/feed/@familynguyenphuong"
                 data-maxcols="1"
                 data-layout="grid"
                 data-poststyle="inline"
                 data-readmore="Read the rest"
                 data-buttonclass="btn btn-primary"
                 data-offset="-100"></div>
          </div>

        </div>

        <div className='follow-panel'>
          <form action="https://subscribe.wordpress.com" method="post" acceptCharset="utf-8" id="subscribe-blog">
            <div className='follow-title'>{fm('Follow My Blog')}</div>
            <div className='follow-text'>{fm('Get new content delivered directly to your inbox')}</div>
            <input id="subscribe-field" type="email" name="email" className='input' placeholder={fm('Enter your email address')}/>
            <input type="hidden" name="action" value="subscribe" />
            <input type="hidden" name="blog_id" value="196125504" />
            <input type="hidden" name="source" value="https://nguyenphuongfamily.org/blog/" />
            <input type="hidden" name="sub-type" value="widget" />
            <input type="hidden" name="redirect_fragment" value="subscribe-blog" />
            <input type="hidden" id="_wpnonce" name="_wpnonce" value="e190a2b217" />
            <button type='button' className='sub-btn' onClick={() => location.href='https://medium.com/@familynguyenphuong/subscribe'}>{fm('Subscribe')}</button>
          </form>
        </div>
      </div>
    </Container>
  );
}

const Container = styled.div`
  .top {
    height: 384px;
    background-image: url('${imgs['Group 2991.png']}');
    background-size: cover;
    padding-left: 7.5%;
    padding-top: 150px;
    @media (max-width: 991px) {
      height: 211px;
      padding-top: 70px;
      margin-top: -30px;
      padding-left: 5%;
      background-image: url('${imgs['Group 3006.png']}');
    }
  }
  //transform: translateY(80px);
  padding-bottom: 80px;
  @media (max-width: 991px) {
    transform: none;
    padding-bottom: 0;
  }

  .header {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px 0 80px 0;
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;

    & > div {
      padding-bottom: 3px;
      border-bottom: 3px solid transparent;
      cursor: pointer;
      &.selected {
        border-color: #FB7E26;
      }
    }
  }
  
  .content {
    width: 85%;
    margin: 40px auto;
    display: flex;
    align-items: flex-start;
    @media (max-width: 991px) {
      flex-flow: column;
      margin-bottom: 0;
    }
  }

  .input {
    width: 305px;
    height: 54px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #828282;
    padding-left: 36px;
    margin-bottom: 24px;
    background: #FFFFFF;
    border-radius: 3px;
    border: none;

    @media (max-width: 991px) {
      margin-bottom: 20px;
      padding-left: 16px;
    }

    &:focus {
      outline: none;
    }
  }


  .des {
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    color: white;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(17, 34, 77, 0.1);
    margin-bottom: 50px;
    max-width: 521px;
    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 19px;
      padding-bottom: 15px;
      width: 80%;
    }

  }
  
  .item {
    padding: 50px 0;
    border-bottom: 1px solid rgba(17, 34, 77, 0.1);
  }
  
  .item-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #828282;
    margin-bottom: 20px;
    & > span {
      color: #000000;
    }
  }
  
  .item-sec-title {
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    color: #000000;
    margin-bottom: 20px;
  }
  
  .item-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
  }
  
  .follow-panel {
    background: #F3F7E6;
    padding: 40px 50px;
    margin-left: 150px;

    @media (max-width: 991px) {
      padding: 32px 20px;
      margin-left: 0;
      width: 120%;
      margin-left: -10%;
    }
  }
  
  .follow-title {
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    color: #11224D;
    margin-bottom: 20px;

    @media (max-width: 991px) {
      font-size: 18px;
      line-height: 25px;
      margin-bottom: 12px;
    }

  }
  
  .follow-text {
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    color: #000000;
    margin-bottom: 30px;

    @media (max-width: 991px) {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 14px;
    }
  }
  
  .sub-btn {
    width: 170px;
    height: 50px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    margin-top: 30px;
    border: none;
    outline: none;
    border-radius: 3px;
    background: #354402;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    text-transform: uppercase;
  }
  
  .read-more {
    text-align: center !important;
    margin-top: 10px;
  }
  
  .blogs {
    width: 95%;
    @media (max-width: 991px) {
      width: 100%;
    }
  }

  .title {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-image: url("${imgs['Group 2899 (2).svg']}");
    background-size: contain;
    height: 103px;
    background-repeat: no-repeat;
    //padding-left: 15px;
    margin-bottom: 15px;

    font-size: 50px;
    padding-left: 12px;
    color: #FFFFFF;


    @media (max-width: 991px) {
      height: 42px;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 10px;
      //justify-content: center;
      //background-position: center;
    }
  }

`;


export default OurBlog;
