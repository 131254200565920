import React, { useState, useEffect, useRef } from 'react';
import importAllImg from "../helpers/import-all-imgs";
import styled, { createGlobalStyle } from 'styled-components';
import {useIntl} from "react-intl";

const imgs = importAllImg(require.context('../img/npf', false, /\.(png|jpe?g|svg)$/));

function News() {

  const { formatMessage } = useIntl();
  const [more, setMore] = useState(false);

  const fm = id => formatMessage({id});

  const arr = [
    {
      img: imgs['image 2179.png'],
      uni: 'Fulbright University Vietnam',
      date: '17 Dec 2022',
      title: 'Changing the world from the smallest & most genuine gestures',
      content: '“As the world is facing many challenges and crises, we must do everything differently to change the system and ensure a sustainable future. One solution is to invest in education, in the people who can create positive impacts for our society,” said Mr...',
      link: 'https://fulbright.edu.vn/nguyen-phuong-lam-scholarship-fulbright-university-vietnam-philanthropy-acumen-academy/'
    },
    {
      img: imgs['image 2180.png'],
      uni: 'Fulbright University Vietnam',
      date: '03 Nov 2022',
      title: '“Intention is more important than action”',
      content: 'Fulbright students and the 2022 cohort of the Social Impact Business Accelerator Program, organized by Acumen Academy and Fulbright University Vietnam, were treated to an enlightening conversation on the role, meaning, and purpose of social...',
      link: 'https://fulbright.edu.vn/intention-is-more-important-than-action-a-lesson-on-how-social-businesses-can-create-positive-impacts-for-society/'
    },
    {
      img: imgs['image 2173.png'],
      uni: 'The Business Times',
      date: '09 Mar 2021',
      title: 'When empathy meets philanthropy',
      content: 'For private equity veteran Lam Nguyen-Phuong, helping social entrepreneurs has nothing to do with dollars and cents. As a private equity veteran of more than two decades, it is fitting that Lam Nguyen- Phuong has chosen social...',
      link: ' https://www.businesstimes.com.sg/wealth-december-2018/when-empathy-meets-philanthropy'
    },
    {
      img: imgs['image 2181.png'],
      uni: 'Fulbright University Vietnam',
      date: '07 Jun 2021',
      title: 'Fulbright University Vietnam and VIETSEEDS Foundation announce.',
      content: 'With alignment in the mission to grow Vietnamese talents to become future change-makers, Fulbright and VietSeeds Foundation are honored to announce the creation of the Nguyễn-Phương Family Scholarship, (“NP Family Scholarship)...',
      link: 'https://fulbright.edu.vn/fulbright-university-vietnam-and-vietseeds-foundation-announce-new-np-family-scholarship-for-incoming-student/'
    },
    {
      img: imgs['Group 1961.png'],
      uni: 'DBS Private Bank',
      date: '09 Mar 2021',
      title: 'Driving Social Impact – An Interview with Mr Lam',
      content: 'Having made private equity investments totaling USD 6 billion in emerging markets around the world, Mr Lam Nguyen Phuong addresses skepticism on social financing and the importance of enabling social entrepreneurs in their impact and growth...',
      link: 'https://www.dbs.com.sg/private-banking/articles/esg/wheels-in-motion'
    },
    {
      img: imgs['image 2178.png'],
      uni: 'The Business Times',
      date: '31 July 2019',
      title: 'The changing face of philanthropy',
      content: 'PHILANTHROPY as we know it is changing. The traditional practice of cheque-giving is making way for the likes of impact investing and venture philanthropy as the wealthy seek newer, more collaborative ways to give back to society - a trend that\'s especially...',
      link: ' https://www.businesstimes.com.sg/wealth/wealth-investing/whos-who-private-banking-july-2019/changing-face-philanthropy'
    }
  ];

  const isMobile = window.innerWidth <= 991;

  const displayArr = isMobile ? (more ? arr : arr.slice(0, 2)): arr

  return (
    <Container>
      <div className='top'>
        <div className='title'>{fm('IN THE NEWS')}</div>
        <div className='des'>
          {fm('Stories that showcase our projects and collaborations with Changemakers across Asia.')}
        </div>
      </div>


      <div className='news-grid'>
        {
          displayArr.map((i, index) => (
            <div className='news-item' key={index}>
              <img className='item-img' src={i.img} />
              <div className='item-content'>
                <div className='item-uni'>{i.uni}</div>
                <div className='item-date'>{i.date}</div>
                <div className='item-title'>{i.title}</div>
                <div className='item-des'>{i.content}</div>
                <div className='item-link'>
                  <a target='_blank' href={i.link}>{fm('Read More')}</a>
                </div>
              </div>
            </div>
          ))
        }
      </div>
      {
        isMobile &&
        <div className='more-panel'>
          <span onClick={() => setMore(i => !i)}>
            { more ? 'See less': 'See more' }
          </span>
        </div>
      }

    </Container>
  )
}

const Container = styled.div`
  
  .more-panel {
    text-align: center;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #354402;
    background: #F3F7E6;
    margin-bottom: 10px;
  }
  //padding-bottom: 80px;

  .top {
    height: 384px;
    background-image: url('${imgs['Group 2992.png']}');
    background-size: cover;
    padding-left: 7.5%;
    padding-top: 150px;
    @media (max-width: 991px) {
      height: 211px;
      margin-top: -50px;
      padding-top: 80px;
    }
  }



  .des {
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    color: white;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(17, 34, 77, 0.1);
    margin-bottom: 50px;
    max-width: 521px;
    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 19px;
      padding-bottom: 15px;
    }

  }
  
  @media (max-width: 991px) {
    transform: none;
    padding-bottom: 0;
  }
  
  .item-img {
    width: 100%;  
  }
  
  .top-panel {
    padding: 44px;
    display: flex;
    flex-flow: column;
    align-items: center;
    @media (max-width: 991px) {
      padding: 28px;
    }
  }
  
  .top-panel-text {
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    color: #828282;
    max-width: 568px;
    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 19px;
      max-width: 100%;
    }
  }
  
  .news-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 45px 60px;
    padding: 56px 7.5%;
    background: #F3F7E6;
    @media (max-width: 991px) {
      grid-template-columns: 1fr;
      grid-gap: 28px;
      padding: 20px 7.5%;
    }
  }
  
  .news-item {
    //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-flow: column;

    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    overflow: hidden;

  }

  .news-item > img {
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  
  .item-content {
    padding: 11px 20px 16px 20px;
    flex: 1;
    display: flex;
    flex-flow: column;
    background: white;
  }
  
  .item-uni {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #11224D;
    margin-bottom: 10px;
    @media (max-width: 991px) {
      font-size: 10px;
      line-height: 14px;
    }
  }
  
  .item-date {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #828282;
    margin-bottom: 15px;
    @media (max-width: 991px) {
      font-size: 10px;
      line-height: 14px;
    }
  }
  
  .item-title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #000000;
    margin-bottom: 13px;
    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 19px;
    }
  }
  
  .item-des {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 10px;
    @media (max-width: 991px) {
      font-size: 10px;
      line-height: 14px;
    }
  }
  
  .item-link {
    text-align: center;
    margin-top: auto;
    & > a {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #354402;


      text-decoration: none;
      @media (max-width: 991px) {
        font-size: 12px;
        line-height: 16px;
      }
      
    }
  }

  .title {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-image: url("${imgs['Group 2899 (2).svg']}");
    background-size: contain;
    height: 103px;
    background-repeat: no-repeat;
    //padding-left: 15px;
    margin-bottom: 15px;

    font-size: 50px;
    padding-left: 5px;
    color: #FFFFFF;


    @media (max-width: 991px) {
      height: 42px;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 20px;
      //justify-content: center;
      //background-position: center;
    }
  }
  
  
`;


export default News;
