import React, { useState, useEffect, useRef } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import importAllImg from "../helpers/import-all-imgs";
import { useParams,  } from "react-router-dom";
import { useIntl } from 'react-intl';


const imgs = importAllImg(require.context('../img/npf', false, /\.(png|jpe?g|svg)$/));

function OurProjects() {

  const [tab, setTab] = useState('current');
  const { id } = useParams();
  const { formatMessage, locale } = useIntl();
  const [show0, setShow0] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const pathName = location.pathname;

  const [themeTab, setThemeTab] = useState(0);
  const [viewMoreEdu, setViewMoreEdu] = useState(false);
  const [viewMoreEduTab, setViewMoreEduTab] = useState(false);
  const [viewMorePov, setViewMorePov] = useState(false);
  const [viewMoreInner, setViewMoreInner] = useState(false);
  const [viewMoreInnerTab, setViewMoreInnerTab] = useState(false);

  const fm = id => formatMessage({id});

  // useEffect(() => {
  //   document.documentElement.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: "instant",
  //   });
  // }, []);
  const isMobile = window.innerWidth <= 991;

  useEffect(() => {
    if (isMobile) {
      setViewMoreEdu(true);
      setViewMorePov(true);
      setViewMoreInner(true);
    }
  }, []);


  if (pathName === '/livelihood') {
    return (
      <Container>

        <div className='top-panel'>
          <div>
            <div className='top-title'>{fm('LIVELIHOOD')}</div>
            <div className='top-text'>
              {fm('We believe that every person has the right to live a life of dignity and have equal opportunity to thrive. We are committed to supporting organizations and changemakers that help community to achieve economic self-reliance and sustainable livelihoods.')}
            </div>
          </div>
        </div>

        <div className='mobile-top'>
          <div className='mobile-top-panel'>
            {fm('LIVELIHOOD')}
          </div>
          <div className='mobile-top-text' >
            {fm('We believe that every person has the right to live a life of dignity and have equal opportunity to thrive. We are committed to supporting organizations and changemakers that help community to achieve economic self-reliance and sustainable livelihoods.')}
          </div>
        </div>


        <div className='themes'>
          <div className='themes-title'>{fm('Themes')}</div>
          <div className='themes-tips'>{fm('Click on relevant tab to learn more')}</div>
          <div className='new-themes-tab'>
            <div onClick={() => setThemeTab(0)} className={themeTab === 0 ? 'themes-tab selected': 'themes-tab'}>{fm('EDUCATION FOR ALL')}</div>
            <div onClick={() => setThemeTab(1)} className={themeTab === 1 ? 'themes-tab selected': 'themes-tab'}>{fm('POVERTY ALLEVIATION')}</div>
            <div onClick={() => setThemeTab(2)} className={themeTab === 2 ? 'themes-tab es-themes-tab selected': 'es-themes-tab themes-tab'}>{fm('ENVIRONMENTAL SUSTAINABILITY')}</div>
          </div>
        </div>
        {
          isMobile && <>
            <div className='mobile-tab-content' style={{display: themeTab === 0 ? '': 'none'}}>
              <img className='mobile-tab-content-img' src={imgs['mario-heller-hXLkFpvKRys-unsplash 10.png']} />
              <div className='mobile-tab-content-title'>
                <img width={38} src={imgs['Book Bookmark1.svg']} />
                <div>{fm('EDUCATION FOR ALL')}</div>
              </div>

              <div className='mobile-tab-content-content'>
                Education is the foundational building block of every society. We believe in the power of individuals and communities to shape their futures, and we recognize that there’s no one right answer to the education challenges communities face. We are embracing education as the engine of opportunity and support a variety of initiatives to give everyone a right to a quality education.

                <div style={{display: viewMoreEduTab? '': 'none'}}>
                  <p></p>
                  We are focused on the following Sustainable Development Goals for Education for All:<br/>
                  <span>Goal 1:</span>Expand early childhood care and education<br/>
                  <span>Goal 2:</span> Provide free and compulsory primary education for all<br/>
                  <span>Goal 3:</span> Promote learning and life skills for young people and adults<br/>
                  <span>Goal 6:</span> Improve the quality of education
                </div>

                <div className='mobile-tab-view-more' onClick={() => setViewMoreEduTab(i => !i)}>
                  { viewMoreEduTab ? fm('Show less'): fm('View more')}
                  <img src={viewMoreEduTab ? imgs['Arrow 3 (1).svg']: imgs['Arrow 3.svg']} />
                </div>

              </div>
            </div>

            <div className='mobile-tab-content' style={{display: themeTab === 1 ? '': 'none'}}>
              <img className='mobile-tab-content-img' src={imgs['hermes-rivera-R1_ibA4oXiI-unsplash 3.png']} />
              <div className='mobile-tab-content-title'>
                <img className='mobile-tab-content-title-img' src={imgs['Hand Heart1.svg']} />
                <div>{fm('POVERTY ALLEVIATION')}</div>
              </div>
              <div className='mobile-tab-content-content'>
                Despite some positive signs toward reducing inequality in some dimensions, inequality still persists. Poverty entails more than the lack of income and productive resources to ensure sustainable livelihoods. In order to build a better world from where we found it, we need to support those that are less fortunate and engage them to build truly sustainable ideas. Reducing inequalities and ensuring no one is left behind are integral to how we encourage a pay it forward society.
              </div>
            </div>

            <div className='mobile-tab-content' style={{display: themeTab === 2 ? '': 'none'}}>
              <img className='mobile-tab-content-img' src={imgs['mike-erskine-GKFsewk-hz0-unsplash 2.png']} />
              <div className='mobile-tab-content-title'>
                <img width={31} src={imgs['pure-natural (1).svg']} />
                <div>{fm('ENVIRONMENTAL SUSTAINABILITY')}</div>
              </div>
              <div className='mobile-tab-content-content'>
                Climate change is a real and undeniable threat to all humans and walks of life on Earth. The effects are already visible and will be catastrophic unless we act now. Through education, innovation and adherence to our climate commitments, we can make the necessary changes to protect the planet.
                <p></p>
                While climate action and environmental sustainability initiatives is new to our Family, we are committed to play a part and improving our investment practices. We are keen to learn from Changemakers, experts, organizations and communities on how we can do better to protect our planet. For change to become transformational, our change in mindset must manifest in our actions.
              </div>
            </div>
          </>
        }

        <div className='tab-content' style={{display: themeTab === 0 ? '': 'none'}}>
          <div className='tab-content-title'>
            <img src={imgs['Book Bookmark1.svg']} />
            <div>{fm('EDUCATION FOR ALL')}</div>
          </div>
          <div className='tab-content-content'>
            {
              locale === 'en' ? <>
                Education is the foundational building block of every society. We believe in the power of individuals and communities to shape their futures, and we recognize that there’s no one right answer to the education challenges communities face. We are embracing education as the engine of opportunity and support a variety of initiatives to give everyone a right to a quality education.
                <p></p>
                We are focused on the following Sustainable Development Goals for Education for All:<br/>
                <span>Goal 1:</span>Expand early childhood care and education<br/>
                <span>Goal 2:</span> Provide free and compulsory primary education for all<br/>
                <span>Goal 3:</span> Promote learning and life skills for young people and adults<br/>
                <span>Goal 6:</span> Improve the quality of education
              </> : <>
                Giáo dục là nền tảng xây dựng của mọi xã hội. Chúng tôi tin vào sức mạnh của các cá nhân và cộng đồng trong việc định hình tương lai của họ và chúng tôi nhận ra rằng không có câu trả lời đúng đắn nào cho những thách thức giáo dục mà cộng đồng phải đối mặt. Chúng tôi coi giáo dục là động lực của cơ hội và hỗ trợ nhiều sáng kiến ​​khác nhau nhằm mang lại cho mọi người quyền được hưởng một nền giáo dục có chất lượng. Chúng tôi tập trung vào các Mục tiêu Phát triển Bền vững về Giáo dục cho Tất cả mọi người sau đây:
                <p></p>
                <span>Mục tiêu 1:</span>Mở rộng chăm sóc và giáo dục mầm non <br/>
                <span>Mục tiêu 2:</span>Cung cấp giáo dục tiểu học bắt buộc và miễn phí cho tất cả mọi người  <br/>
                <span>Mục tiêu 3:</span>Thúc đẩy học tập và kỹ năng sống cho thanh thiếu niên và người lớn <br/>
                <span>Mục tiêu 6:</span>Nâng cao chất lượng giáo dục <br/>
              </>
            }

          </div>
          <img src={imgs['Group 2964.png']} />
        </div>

        <div className='tab-content' style={{display: themeTab === 1 ? '': 'none'}}>
          <div className='tab-content-title'>
            <img src={imgs['Hand Heart1.svg']} />
            <div>{fm('POVERTY ALLEVIATION')}</div>
          </div>
          <div className='tab-content-content'>
            Despite some positive signs toward reducing inequality in some dimensions, inequality still persists. Poverty entails more than the lack of income and productive resources to ensure sustainable livelihoods. In order to build a better world from where we found it, we need to support those that are less fortunate and engage them to build truly sustainable ideas. Reducing inequalities and ensuring no one is left behind are integral to how we encourage a pay it forward society.
          </div>
          <img src={imgs['Group 2972.png']} />
        </div>

        <div className='tab-content' style={{display: themeTab === 2 ? '': 'none'}}>
          <div className='tab-content-title'>
            <img src={imgs['pure-natural (1).svg']} />
            <div>{fm('ENVIRONMENTAL SUSTAINABILITY')}</div>
          </div>
          <div className='tab-content-content'>
            Climate change is a real and undeniable threat to all humans and walks of life on Earth. The effects are already visible and will be catastrophic unless we act now. Through education, innovation and adherence to our climate commitments, we can make the necessary changes to protect the planet.
            <p></p>
            While climate action and environmental sustainability initiatives is new to our Family, we are committed to play a part and improving our investment practices. We are keen to learn from Changemakers, experts, organizations and communities on how we can do better to protect our planet. For change to become transformational, our change in mindset must manifest in our actions.
          </div>
          <img src={imgs['Group 2979.png']} />
        </div>

        <div className='initiatives' style={{display: themeTab === 0 ? '': 'none'}}>
          <div className='initiatives-title'>{fm('EDUCATION FOR ALL INITIATIVES')}</div>
          <div className='initiatives-current'>{fm('Current Projects')}</div>
          <div className='initiatives-text'>{fm('To realize this aim, here are the current organizations and projects we support:')}</div>
          <div className='initiatives-current initiatives-items-title'>{fm('Scholarships')}</div>

          <div className='initiatives-items'>
            <div className='initiatives-item'>
              <img src={isMobile ? imgs['Group 2944 (2).png']: imgs['Group 2965 (2).svg']} />
              <div className='initiatives-item-content'>
                <div className='initiatives-item-title'>
                  NP Family Changemaker Scholarship <br/>
                  @FULBRIGHT UNIVERSITY VIETNAM, Vietnam
                </div>
                <div className='initiatives-item-text'>
                  Fulbright is the country’s first not-for-profit, independent university offering a liberal arts undergraduate program. It strives to close the gap between higher education and Vietnam’s greatest needs by building the next generation of Vietnamese leaders equipped with the skills to tackle global challenges.
                  <p></p>
                  With alignment in core values and in the mission to grow Vietnamese talents to become future changemakers, Fulbright University, VietSeeds Foundation and our family created the Nguyễn-Phương Family Scholarship (“NP Family Scholarship”).
                  <p></p>
                  The spirit of the scholarship is to help form generations of Vietnamese leaders/changemakers (in all fields of society) for a more humane, kinder world – a world built on solidarity instead of competition; a world focusing on broad human development and social sustainability instead of just economic and material development; a world based on the principle that the meaning of human life is in the Being, and not in the Having.
                </div>
                <div className='initiatives-item-btn' onClick={() => window.open('https://fulbright.edu.vn/fulbright-university-vietnam-and-vietseeds-foundation-announce-new-np-family-scholarship-for-incoming-student/')}>
                  {fm('Learn more')}
                </div>
              </div>
            </div>

            <div className='initiatives-item'>
              <img src={isMobile ? imgs['Group 2943 (1).png']: imgs['Group 2966.svg']} />
              <div className='initiatives-item-content'>
                <div className='initiatives-item-title'>
                  VIETSEEDS FOUNDATION, Vietnam
                </div>
                <div className='initiatives-item-text'>
                  VietSeeds is a non-profit organization committed to bringing EQUAL ACCESS to college education for ALL students in Vietnam. VietSeeds invests in highly motivated students from underserved demographics. They empower scholars to have rich intellectual lives, fulfilling careers, not only to become financially independent but also to be engaging citizens so that they can start giving back to society.
                </div>
                <div className='initiatives-item-btn' onClick={() => window.open('http://vietseeds.org/')}>
                  {fm('Learn more')}
                </div>
              </div>
            </div>

            <div className='initiatives-item'>
              <img src={isMobile ? imgs['Group 2945.png']: imgs['Group 2967 (1).svg']} />
              <div className='initiatives-item-content'>
                <div className='initiatives-item-title'>
                  TEACH FOR VIETNAM, Vietnam
                </div>
                <div className='initiatives-item-text'>
                  Teach For Vietnam is an independent member of the global network Teach For All, consisting of 56 member countries, working together towards eradicating educational inequalities and building a complete education for children, especially those in remote areas.
                </div>
                <div className='initiatives-item-btn' onClick={() => window.open('https://teachforvietnam.org/')}>
                  {fm('Learn more')}
                </div>
              </div>
            </div>

            <div className='initiatives-item'>
              <img src={isMobile ? imgs['Group 2946.png']: imgs['Group 2968 (1).svg']} />
              <div className='initiatives-item-content'>
                <div className='initiatives-item-title'>
                  HIGHER EDUCATION SCHOLARSHIPS:
                </div>
                <div className='initiatives-item-text'>
                  <ul className='list-item-ul'>
                    <li>The Nguyen Phuong Thiep Fellowship Fund at Stanford Graduate School of Business, USA</li>
                    <li>The Jane Kim and Jonathan Nguyen-Phuong Family Endowed Scholarship at Georgetown University, USA</li>
                    <li>The Friends of Luke Rogers International Programs Endowed Fund at Pitzer College, USA</li>
                    <li>The Lam-Anh Jennifer Nguyen-Phuong Scholarship Fund at Scripps College, USA</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {
            isMobile &&           <div className='mobile-initiatives-items'>
              <div className='initiatives-item-mobile'>
                <div>
                  <img src={isMobile ? imgs['image 2217.svg']: imgs['Group 2965 (2).svg']} />
                </div>
                <div className='initiatives-item-content-mobile'>
                  <div className='initiatives-item-title-mobile'>
                    NP FAMILY CHANGEMAKER <br/> SCHOLARSHIP, VIETNAM
                  </div>
                  <div className='initiatives-item-btn-mobile' onClick={() => window.open('https://fulbright.edu.vn/fulbright-university-vietnam-and-vietseeds-foundation-announce-new-np-family-scholarship-for-incoming-student/')}>
                    <img src={imgs['arrow-down-right-svgrepo-com 1.svg']} />
                  </div>
                </div>
              </div>

              <div className='initiatives-item-mobile initiatives-item-mobile2'>
                <div>
                  <img src={isMobile ? imgs['image 2221.svg']: imgs['Group 2965 (2).svg']} />
                </div>
                <div className='initiatives-item-content-mobile'>
                  <div className='initiatives-item-title-mobile'>
                    VIETSEEDS FOUNDATION, <br/> Vietnam
                  </div>
                  <div className='initiatives-item-btn-mobile' onClick={() => window.open('http://vietseeds.org/')}>
                    <img src={imgs['arrow-down-right-svgrepo-com 1.svg']} />
                  </div>
                </div>
              </div>

              <div className='initiatives-item-mobile initiatives-item-mobile3'>
                <div>
                  <img src={isMobile ? imgs['logo 1.svg']: imgs['Group 2965 (2).svg']} />
                </div>
                <div className='initiatives-item-content-mobile'>
                  <div className='initiatives-item-title-mobile'>
                    TEACH FOR VIETNAM, <br/> Vietnam
                  </div>
                  <div className='initiatives-item-btn-mobile' onClick={() => window.open('https://teachforvietnam.org/')}>
                    <img src={imgs['arrow-down-right-svgrepo-com 1.svg']} />
                  </div>
                </div>
              </div>

              <div className='initiatives-item-mobile initiatives-item-mobile2'>
                <div>
                  <img src={isMobile ? imgs['graduation-mortarboard-svgrepo-com.svg']: imgs['Group 2968 (1).svg']} />
                </div>
                <div className='initiatives-item-content-mobile'>
                  <div className='initiatives-item-title-mobile'>
                    HIGHER EDUCATION SCHOLARSHIPS:
                  </div>
                  <div className='initiatives-item-text-mobile'>
                    Stanford Graduate School of Business, Georgetown University, Pitzer College and Scripps College
                  </div>
                </div>
              </div>
            </div>
          }

          <div className='initiatives-current initiatives-pervious'>previous Projects</div>

          <div className='descktop-previous' style={{display: viewMoreEdu? '': 'none'}}>
            <div className='initiatives-item'>
              <img src={isMobile ? imgs['Group 2947.png']:imgs['Group 2969 (1).svg']} />
              <div className='initiatives-item-content'>
                <div className='initiatives-item-title'>
                  PACIFIC LINKS FOUNDATION, Vietnam
                </div>
                <div className='initiatives-item-text'>
                  Pacific Links Foundation breaks the cycle of poverty and trafficking by empowering women and youth through improving life skills, raising self-awareness, bringing new knowledge and opening opportunities and supportive networks.
                  <p></p>
                  Our family supports two education projects in 2018-2022:
                  <p></p>
                  <ul className='initiatives-item-ul'>
                    <li>
                      <span>Scholarship to Elevate Education & Develop Skills:</span> SEEDS provides educational opportunities for talented disadvantaged students in rural areas of Central Provinces to equip them with essential life skills, help guide them towards a career choice and expand job opportunities.
                    </li>
                    <li>
                      <span>Girls Empowerment Scholarships:</span> GEMS provides long-term educational and life skills development opportunities for disadvantaged, at-risk girls residing in trafficking hotspots along the Vietnam-Cambodia border who are at risk of dropping out to school.
                    </li>
                    <li>
                      <span>Socio-Emotional Learning:</span> Sponsors mindfulness training for Pacific Links staff and SEEDS teachers.
                    </li>
                  </ul>
                </div>
                <div className='initiatives-item-btn' onClick={() => window.open('https://pacificlinks.org/')}>
                  {fm('Learn more')}
                </div>
              </div>
            </div>

            <div className='initiatives-item'>
              <img src={isMobile ? imgs['Group 2948.png']: imgs['Group 2970 (1).svg']} />
              <div className='initiatives-item-content'>
                <div className='initiatives-item-title'>
                  CHOKI TRADITIONAL ART SCHOOL, Bhutan
                </div>
                <div className='initiatives-item-text'>
                  Located just north of the capital of Bhutan, in the beautiful mountain valley of Kabesa, Choki Traditional Art School is the only private institute that provides free education to underprivileged youths who have a keen interest in learning the traditional arts and crafts. The school focuses on developing solid background and skills of the students so that they can go on to become independent and contribute to preserving Bhutan’s unique art and culture.
                </div>
                <div className='initiatives-item-btn' onClick={() => window.open('https://chokischool.org/')}>
                  {fm('Learn more')}
                </div>
              </div>
            </div>

            <div className='initiatives-item'>
              <img src={isMobile ? imgs['Group 2949.png']: imgs['Group 2971.svg']} />
              <div className='initiatives-item-content'>
                <div className='initiatives-item-title'>
                  THE CHANGE LEADER ACADEMY, Singapore & China
                </div>
                <div className='initiatives-item-text'>
                  The Change Leader Academy aims to free the creativity and innovation of Chinese youth that are authentic to their passions and interests. We run human centered design workshops to middle and high school students to empathize with communities and develop independent ventures that have positive social impact. So far, CLA has completed workshops with over 600 students in 5 schools and with partners such as UN Women and the Global Issues Network (GIN) Conference.
                </div>
              </div>
            </div>
          </div>

          {
            isMobile &&           <div className='mobile-initiatives-items'>
              <div className='initiatives-item-mobile initiatives-item-mobile5'>
                <div>
                  <img src={isMobile ? imgs['Group 2957.svg']: imgs['Group 2965 (2).svg']} />
                </div>
                <div className='initiatives-item-content-mobile'>
                  <div className='initiatives-item-title-mobile'>
                    PACIFIC LINKS <br/> FOUNDATION, Vietnam
                  </div>
                  <div className='initiatives-item-btn-mobile' onClick={() => window.open('https://pacificlinks.org/')}>
                    <img src={imgs['arrow-down-right-svgrepo-com 1.svg']} />
                  </div>
                </div>
              </div>

              <div className='initiatives-item-mobile initiatives-item-mobile2 initiatives-item-mobile6'>
                <div>
                  <img src={isMobile ? imgs['logo-transparent-new-new-to-be-cropped 1.svg']: imgs['Group 2965 (2).svg']} />
                </div>
                <div className='initiatives-item-content-mobile'>
                  <div className='initiatives-item-title-mobile'>
                    CHOKI TRADITIONAL ART <br/> SCHOOL, Bhutan
                  </div>
                  <div className='initiatives-item-btn-mobile' onClick={() => window.open('https://chokischool.org/')}>
                    <img src={imgs['arrow-down-right-svgrepo-com 1.svg']} />
                  </div>
                </div>
              </div>


              <div className='initiatives-item-mobile initiatives-item-mobile2 initiatives-item-mobile5'>
                <div>
                  <img src={isMobile ? imgs['image2vector (1) 11.svg']: imgs['Group 2968 (1).svg']} />
                </div>
                <div className='initiatives-item-content-mobile'>
                  <div className='initiatives-item-title-mobile'>
                    THE CHANGE LEADER ACADEMY, Singapore & China
                  </div>
                  <div className='initiatives-item-btn-mobile'>
                    <img src={imgs['arrow-down-right-svgrepo-com 1.svg']} />
                  </div>
                </div>
              </div>
            </div>
          }
          <div className='view-more' onClick={() => setViewMoreEdu(i => !i)}>
            { viewMoreEdu ? fm('Show less'): fm('View more')}
            <img src={viewMoreEdu ? imgs['Arrow 3 (1).svg']: imgs['Arrow 3.svg']} />
          </div>
        </div>

        <div className='initiatives' style={{display: themeTab === 1 ? '': 'none'}}>
          <div className='initiatives-title'>poverty alleviation initiatives</div>
          <div className='initiatives-current'>{fm('Current Projects')}</div>
          <div className='initiatives-text'>{fm('To realize this aim, here are the current organizations and projects we support:')}</div>
          <div className='initiatives-items'>
            <div className='initiatives-item'>
              <img src={isMobile ? imgs['Group 2950.png']: imgs['Group 2973.svg']} />
              <div className='initiatives-item-content'>
                <div className='initiatives-item-title'>
                  AGAPE CONNECTING PEOPLE, Singapore
                </div>
                <div className='initiatives-item-text'>
                  Agape is a Singapore company that provides reskilling and employment opportunities for a sustainable community transformation. Agape serves the disadvantaged people who want to turn their lives around – ex-offenders, inmates serving time in Changi, physically challenged persons, and single mothers.
                </div>
                <div className='initiatives-item-btn' onClick={() => window.open('https://agape-cp.com/')}>
                  {fm('Learn more')}
                </div>
              </div>
            </div>

            <div className='initiatives-item'>
              <img src={isMobile ? imgs['Group 2951 (1).png']: imgs['Group 2974.svg']} />
              <div className='initiatives-item-content'>
                <div className='initiatives-item-title'>
                  FUGEE ORG, Malaysia
                </div>
                <div className='initiatives-item-text'>
                  Over the last 14 years, Fugee has established itself as a leading educational non-profit that paves the way for refugee education and employability in Kuala Lumpur, Malaysia. They champion equality and access by and with refugees to bridge learning gaps and nurture self reliance to build a truly inclusive society. They have served 600+ students at Fugee School and are currently working with partners and community leaders to exercise a child's right to education.
                </div>
                <div className='initiatives-item-btn' onClick={() => window.open('https://www.fugee.org/en-sg')}>
                  {fm('Learn more')}
                </div>
              </div>
            </div>

            <div className='initiatives-item'>
              <img src={isMobile ? imgs['Group 2951 (1).png']: imgs['Group 2975 (1).svg']} />
              <div className='initiatives-item-content'>
                <div className='initiatives-item-title'>
                  UNHCR, THE UN REFUGEE AGENCY, Myanmar
                </div>
                <div className='initiatives-item-text'>
                  Over a million Rohingya refugees have fled violence in Myanmar in successive waves of displacement since the early 1990s. UNHCR is working to mainstream refugee protection in all refugee settlements. With its partners, it is developing a referral system and safe spaces for survivors of gender-based violence. We are also enhancing efforts to identify and refer children at risk for the appropriate support.
                </div>
                <div className='initiatives-item-btn' onClick={() => window.open('https://www.unhcr.org/emergencies/rohingya-emergency')}>
                  {fm('Learn more')}
                </div>
              </div>
            </div>

          </div>

          {
            isMobile &&           <div className='mobile-initiatives-items'>

              <div className='initiatives-item-mobile initiatives-item-mobile2' style={{background: '#BB141A'}}>
                <div>
                  <img src={isMobile ? imgs['agape 1.svg']: imgs['Group 2965 (2).svg']} />
                </div>
                <div className='initiatives-item-content-mobile'>
                  <div className='initiatives-item-title-mobile'>
                    AGAPE CONNECTING PEOPLE, Singapore
                  </div>
                  <div className='initiatives-item-btn-mobile' onClick={() => window.open('https://agape-cp.com/')}>
                    <img src={imgs['arrow-down-right-svgrepo-com 1.svg']} />
                  </div>
                </div>
              </div>

              <div className='initiatives-item-mobile'  style={{background: '#63AFBC'}}>
                <div>
                  <img src={isMobile ? imgs['image 2227.svg']: imgs['Group 2965 (2).svg']} />
                </div>
                <div className='initiatives-item-content-mobile'>
                  <div className='initiatives-item-title-mobile'>
                    FUGEE ORG, Malaysia
                  </div>
                  <div className='initiatives-item-btn-mobile' onClick={() => window.open('https://www.fugee.org/en-sg')}>
                    <img src={imgs['arrow-down-right-svgrepo-com 1.svg']} />
                  </div>
                </div>
              </div>

              <div className='initiatives-item-mobile initiatives-item-mobile2' style={{background: '#0072BD'}}>
                <div>
                  <img src={isMobile ? imgs['image 2225.svg']: imgs['Group 2968 (1).svg']} />
                </div>
                <div className='initiatives-item-content-mobile'>
                  <div className='initiatives-item-title-mobile'>
                    UNHCR, THE UN REFUGEE AGENCY, Myanmar
                  </div>
                  <div className='initiatives-item-btn-mobile' onClick={() => window.open('https://www.unhcr.org/emergencies/rohingya-emergency')}>
                    <img src={imgs['arrow-down-right-svgrepo-com 1.svg']} />
                  </div>
                </div>
              </div>
            </div>
          }


          <div className='initiatives-current initiatives-pervious'>previous Projects</div>

          <div className='descktop-previous' style={{display: viewMorePov? '': 'none'}}>

            <div className='initiatives-item'>
              <img src={isMobile? imgs['Group 2953.png']: imgs['Group 2976 (1).svg']} />
              <div className='initiatives-item-content'>
                <div className='initiatives-item-title'>
                  SYMPAMEALS ĐỒNG CẢM, Vietnam
                </div>
                <div className='initiatives-item-text'>
                  ĐỒNG CẢM is a private charity fund established in 2005 to help poor cancer patients as well as people in difficult personal circumstances in Hanoi. Offers free meal coupons and milk powder, as well as pays for medical treatment and travel expenses. Also provides scholarships to help children of cancer patients stay in school.
                </div>
                <div className='initiatives-item-btn'>
                  {fm('Learn more')}
                </div>
              </div>
            </div>

            <div className='initiatives-item'>
              <img src={isMobile ? imgs['Group 2954.png']: imgs['Group 2977 (1).svg']} />
              <div className='initiatives-item-content'>
                <div className='initiatives-item-title'>
                  FRIENDS OF THE UNDERPRIVILEGED, Vietnam
                </div>
                <div className='initiatives-item-text'>
                  Friends of the Underprivileged (FOU) is a nonprofit group of international friends, who voluntarily get together in our personal capacity, working on the sole vision of helping the underprivileged in Asia regardless of their race, age, gender, religion, or political opinion.
                </div>
                <div className='initiatives-item-btn' onClick={() => window.open('http://www.fou2asia.org/')}>
                  {fm('Learn more')}
                </div>
              </div>
            </div>

            <div className='initiatives-item'>
              <img src={isMobile ? imgs['Group 2955.png']: imgs['Group 2978 (1).svg']} />
              <div className='initiatives-item-content'>
                <div className='initiatives-item-title'>
                  EMPOWERING CHILDREN OF VIETNAM, Vietnam
                </div>
                <div className='initiatives-item-text'>
                  Empowering Children Of Vietnam (ECOV) is a Global Concern which strives to support children in Vietnam. ECOV was established as a Global Concern in 2003 supporting a variety of projects associated with supporting street children with education, shelter and safety. ECOV also supports a variety of orphanages with scholarships and donations to support children with leprosy.
                </div>
                <div className='initiatives-item-btn' onClick={() => window.open(' https://www.uwcsea.edu.sg/learning/service/global-service')}>
                  {fm('Learn more')}
                </div>
              </div>
            </div>
          </div>

          {
            isMobile &&           <div className='mobile-initiatives-items'>

              <div className='initiatives-item-mobile initiatives-item-mobile2' style={{background: '#E07A1E'}}>
                <div>
                  <img src={isMobile ? imgs['Group 3001.svg']: imgs['Group 2965 (2).svg']} />
                </div>
                <div className='initiatives-item-content-mobile'>
                  <div className='initiatives-item-title-mobile'>
                    SYMPAMEALS ĐỒNG CẢM, Vietnam
                  </div>
                  <div className='initiatives-item-btn-mobile'>
                    <img src={imgs['arrow-down-right-svgrepo-com 1.svg']} />
                  </div>
                </div>
              </div>

              <div className='initiatives-item-mobile'  style={{background: '#37CB38'}}>
                <div>
                  <img src={isMobile ? imgs['Group 3002.svg']: imgs['Group 2965 (2).svg']} />
                </div>
                <div className='initiatives-item-content-mobile'>
                  <div className='initiatives-item-title-mobile'>
                    FRIENDS OF THE UNDERPRIVILEGED, Vietnam
                  </div>
                  <div className='initiatives-item-btn-mobile' onClick={() => window.open('http://www.fou2asia.org/')}>
                    <img src={imgs['arrow-down-right-svgrepo-com 1.svg']} />
                  </div>
                </div>
              </div>

              <div className='initiatives-item-mobile initiatives-item-mobile2' style={{background: '#136B95'}}>
                <div>
                  <img src={isMobile ? imgs['Group 3003.svg']: imgs['Group 2968 (1).svg']} />
                </div>
                <div className='initiatives-item-content-mobile'>
                  <div className='initiatives-item-title-mobile'>
                    EMPOWERING CHILDREN OF VIETNAM, Vietnam
                  </div>
                  <div className='initiatives-item-btn-mobile' onClick={() => window.open('https://www.uwcsea.edu.sg/learning/service/global-service\'')}>
                    <img src={imgs['arrow-down-right-svgrepo-com 1.svg']} />
                  </div>
                </div>
              </div>
            </div>
          }
          <div className='view-more' onClick={() => setViewMorePov(i => !i)}>
            { viewMorePov ? fm('Show less'): fm('View more')}
            <img src={viewMorePov ? imgs['Arrow 3 (1).svg']: imgs['Arrow 3.svg']} />
          </div>
        </div>


        <div  className='themes themes-special' style={{display: themeTab === 2 ? '': 'none'}}>
          <div className='themes-title'>{fm('CLIMATE ACTION RESOURCES')}</div>
          <div className='following-grid'>
            <div className='following-content' style={{background: '#3E7E45'}} >
              <div>
                <img  className='special' src={isMobile ? imgs['image 2233.svg']: imgs['image 2233.svg']} />
              </div>
              <div>
                <div className='following-content-text'>
                  The 17 Sustainable Development Goals of the United Nations to address the world’s most pressing issues.
                </div>
                <span className='learn-more'><img src={imgs['arrow-down-right-svgrepo-com 1.svg']} /></span>
              </div>
            </div>

            <div className='following-content' style={{background: '#003334'}} >
              <div>
                <img  className='special' src={isMobile? imgs['image 2235.svg']: imgs['image 2235.svg']} />
              </div>
              <div>
                <div className='following-content-text'>
                  The Earthshot Prize is a global challenge based on five Earthshots – ambitious goals for 2030 to fix our biggest environmental problems.
                </div>
                <span className='learn-more'><img src={imgs['arrow-down-right-svgrepo-com 1.svg']} /></span>
              </div>
            </div>

            <div className='following-content' style={{background: '#0E0E0E'}}>
              <div>
                <img className='special' src={isMobile ? imgs['image 2237.svg'] : imgs['image 2237.svg']} />
              </div>
              <div>
                <div className='following-content-text'>
                  Project Drawdown is the world's leading resource for climate solutions. Our mission is to help the world stop climate change.
                </div>
                <span className='learn-more'><img src={imgs['arrow-down-right-svgrepo-com 1.svg']} /></span>
              </div>
            </div>

            <div className='following-content' style={{background: '#000000'}}>
              <div>
                <img src={imgs['image 2238.svg']} />
              </div>
              <div>
                <div className='following-content-text'>
                  B Lab is the nonprofit network transforming the global economy to benefit all people, communities, and the planet.
                </div>
                <span className='learn-more'><img src={imgs['arrow-down-right-svgrepo-com 1.svg']} /></span>
              </div>
            </div>


            <div className='following-content' style={{background: '#71A32B'}}>
              <div>
                <img className='following-content-img5' src={imgs['image 2244.svg']} />
              </div>
              <div>
                <div className='following-content-text'>
                  Protected Planet is the most up to date and complete source of data on protected areas and other effective area-based conservation measures
                </div>
                <span className='learn-more'><img src={imgs['arrow-down-right-svgrepo-com 1.svg']} /></span>
              </div>
            </div>

            <div className='following-content' style={{background: '#00283E'}}>
              <div>
                <img className='following-content-img5' src={imgs['image 2240.svg']} />
              </div>
              <div>
                <div className='following-content-text'>
                  Giving to Amplify Earth Action (GAEA) Identifies, convenes and amplifies new and existing public, private and philanthropic partnerships for climate and nature.

                </div>
                <span className='learn-more'><img src={imgs['arrow-down-right-svgrepo-com 1.svg']} /></span>
              </div>
            </div>

            <div className='following-content' style={{background: '#000000'}}>
              <div>
                <img className='following-content-img6' src={imgs['image 2245.svg']} />
              </div>
              <div>
                <div className='following-content-text'>
                  GreenBiz Group is a media and events company that accelerates the just transition to a clean economy.

                </div>
                <span className='learn-more'><img src={imgs['arrow-down-right-svgrepo-com 1.svg']} /></span>
              </div>
            </div>

            <div className='following-content' style={{background: '#001C18'}}>
              <div>
                <img src={imgs['image 2242.svg']} />
              </div>
              <div>
                <div className='following-content-text'>
                  Asia Pacific’s largest media and business intelligence organisation dedicated to sustainable development and ESG performance.

                </div>
                <span className='learn-more'><img src={imgs['arrow-down-right-svgrepo-com 1.svg']} /></span>
              </div>
            </div>

            <div className='following-content' style={{background: '#28A745'}}>
              <div>
                <img src={imgs['image 2246.svg']} />
              </div>
              <div>
                <div className='following-content-text'>
                  Conservation Vietnam is the first NGO licensed by MOHA in the wildlife conservation sector and makes grants to support Vietnamese organisations engaged in conservation activities.

                </div>
                <span className='learn-more'><img src={imgs['arrow-down-right-svgrepo-com 1.svg']} /></span>
              </div>
            </div>

          </div>
        </div>

        <div className='talk-panel'>
          <div>
            {fm('“If we had kept the vision of interconnectedness, we would not have created the kind of environmental crisis facing the world today.”')}
          </div>

          <div> — Satish Kumar</div>
        </div>
      </Container>
    );
  }

  if (pathName === '/inner-change') {
    return (
      <Container>
        <div className='top-panel top-panel2'>
          <div>
            <div className='top-title'>{fm('INNER CHANGE')}</div>
            <div className='top-text'>
              {fm('Rebuild the connections between human and all beings')}
            </div>
          </div>
        </div>

        <div className='mobile-top'>
          <div className='mobile-top-panel mobile-top-panel1'>
            {fm('INNER CHANGE')}
          </div>
          {/*<div className='mobile-top-text' >*/}
            {/*{fm('Our civilization has become debased and directionless, leading to catastrophic situations in many aspects of life: ever-increasing inequality and decrease in social mobility; extreme climate changes; lying becoming the new normal; and worst of all, a hardening of the heart. Humanity is at a stage where we need a change in consciousness, with new goals grounded in the notion of interconnectedness. We believe that without fostering Inner Change, no sustainable societal change is possible.')}*/}
            {/*Our civilisation has become debased, leading to catastrophic situations in many aspects of life: ever-increasing inequality and decrease in social mobility; extreme climate changes; lying becoming the new normal; and worst of all, a hardening of the heart. Humanity is at a stage where we need a change in consciousness, with new goals grounded in the notion of interconnectedness.*/}
            {/*<p></p>*/}
            {/*We believe that without fostering Inner Change, no sustainable societal change is possible*/}
          {/*</div>*/}
        </div>

        {
          isMobile &&
          <div className='mobile-tab-content'>
            <img className='mobile-tab-content-img' src={imgs['Group 2959 (1).png']} />
            <div className='mobile-tab-content-title'>
              <img width={20} src={imgs['Meditation1.svg']} />
              <div>{fm('INNER CHANGE')}</div>
            </div>

            <div className='mobile-tab-content-content'>
              Our civilization has become debased and directionless, leading to catastrophic situations in many aspects of life: worsening mental health issues; ever-increasing inequity and decrease in social mobility; polarizing socio-political conflicts; unprecedented environmental destruction; and worst of all, a hardening of the heart.

              <div style={{display: viewMoreInnerTab? '': 'none'}}>
                <p></p>
                Humanity is at a stage where we need to re-write the source code of our civilization. We believe that without <span> Inner Change and an evolution in Consciousness, no sustainable societal change is possible.</span>
              </div>

              <div className='mobile-tab-view-more' onClick={() => setViewMoreInnerTab(i => !i)}>
                { viewMoreInnerTab ? fm('Show less'): fm('View more')}
                <img src={viewMoreInnerTab ? imgs['Arrow 3 (1).svg']: imgs['Arrow 3.svg']} />
              </div>

            </div>
          </div>
        }

        <div className='tab-content tab-content-special'>
          <div className='tab-content-title'>
            <img src={imgs['Meditation1.svg']} />
            <div>{fm('INNER CHANGE')}</div>
          </div>
          <div className='tab-content-content'>

            {fm('Our civilization has become debased and directionless, leading to catastrophic situations in many aspects of life: worsening mental health issues; ever-increasing inequity and decrease in social mobility; polarizing socio-political conflicts; unprecedented environmental destruction; and worst of all, a hardening of the heart.')}
            <p></p>
            {
              locale === 'en' ? <>
                Humanity is at a stage where we need to re-write the source code of our civilization. We believe that without <span> Inner Change and an evolution in Consciousness, no sustainable societal change is possible.</span>
              </>: <>
                Nhân loại đang ở giai đoạn cần viết lại mã nguồn của nền văn minh nhân loại. Chúng tôi tin rằng nếu không có Sự thay đổi bên trong và sự tiến hóa trong Ý thức thì không thể có sự thay đổi xã hội bền vững.
              </>
            }
          </div>
          <img src={imgs['Group 2958 (1).png']} />
        </div>

        <div className='themes'>

          <div className='themes-title'>{fm('OUR VISION')}</div>
          <div className='themes-text'>
            {
              fm('Our Vision is to build a hub promoting various pathways to well-being and the flourishing of Life. The hub’s mission is to foster Inner Change, by equipping changemakers and leaders with a new mindset for a journey of change in a VUCA world (volatility, uncertainty, complexity, and ambiguity). This mindset is rooted in interconnectedness and compassion, and assisted by approaches like mindfulness.')
            }
          </div>

          <div className='themes-title'>{fm('OUR APPROACH')}</div>
          <div className='themes-text'>
            {fm('We support all efforts that foster inner change at the individual level and scale it up to organizations. This includes, but its not limited to: train-the-trainers programs, events and convenings to share best practices and promote collaborations.')}
            <p></p>
            {fm('The First Principles guiding our work are as follows:')}
            <ul className='themes-text-ul'>
              <li>
                1.{fm('Focus on root causes behind the surface symptoms')}
              </li>
              <li>
                2.{fm('Work on high-leverage areas with intersectionality')}
              </li>
              <li>
                3.{fm('Be the catalytic capital for social change and innovation')}
              </li>
              <li>
                4.{fm('Co-creation and co-funding with like-minded partners for sustainability and synergy')}
              </li>
            </ul>

          </div>

        </div>

        {
          isMobile && <div className="mobile-inner-middle-panel">
            <div className='mobile-middle-panel-item'>
              <div>
                <img className='mobile-middle-panel-item-top-img' src={imgs['Group 3007.png']} />
              </div>
              <div className='mobile-themes-tab-content'>
                <div>{fm('MINDFULNESS IN EDUCATION')}</div>
                <div>
                  {fm('Working with different stakeholders in education to bring mindfulness in education. We aim to provide reactive and proactive initiatives through the following: ')}
                  <ul>
                    <li>1.{fm('Train-the-trainer model for mindfulness to be incorporated into schools and communities ')}</li>
                    <li>2.{fm('Developing Mindfulness Based Stress Reduction (MBSR) therapy to schools')}</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className='mobile-middle-panel-item'>
              <div>
                <img className='mobile-middle-panel-item-bottom-img' src={imgs['Group 3008.png']} />
              </div>
              <div className='mobile-themes-tab-content'>
                <div>{fm('MINDFULNESS IN BUSINESS')}</div>
                <div>
                  {fm('Working with companies to foster change in their organizational culture that considers all stakeholders and is respectful to nature. The hub will convene events and gatherings to allow business and leaders to share best practices and foster collaborations.')}
                </div>
              </div>

            </div>


          </div>
        }

        <div className='middle-panel inner-middle-panel'>
          <div className='middle-panel-item'>
            <div>
              <img className='middle-panel-item-top-img' src={imgs['image 2194 (1).png']} />
            </div>
            <div className='themes-tab-content middle-panel-item-right'>
              <div>{fm('MINDFULNESS IN EDUCATION')}</div>
              <div>
                {fm('Working with different stakeholders in education to bring mindfulness in education. We aim to provide reactive and proactive initiatives through the following: ')}
                <ul>
                  <li>{fm('Train-the-trainer model for mindfulness to be incorporated into schools and communities ')}</li>
                  <li>{fm('Developing Mindfulness Based Stress Reduction (MBSR) therapy to schools')}</li>
                </ul>
              </div>
            </div>
          </div>

          <div className='middle-panel-item'>
            <div className='themes-tab-content middle-panel-item-left'>
              <div>{fm('MINDFULNESS IN BUSINESS')}</div>
              <div>
                {fm('Working with companies to foster change in their organizational culture that considers all stakeholders and is respectful to nature. The hub will convene events and gatherings to allow business and leaders to share best practices and foster collaborations.')}
              </div>
            </div>

            <div>
              <img className='middle-panel-item-bottom-img' src={imgs['95aae87cd2ded08bcfe1a980cbc182d 1 (1).png']} />
            </div>
          </div>
        </div>

        <div className='initiatives' >
          <div className='initiatives-title'>{fm('INNER CHANGE initiatives')}</div>
          <div className='initiatives-current'>{fm('Current Projects')}</div>
          <div className='initiatives-text'>{fm('To realize this aim, here are the current organizations and projects we support:')}</div>
          <div className='initiatives-items'>

            <div className='initiatives-item'>
              <img src={isMobile? imgs['Group 2956.png']: imgs['Group 2981.svg']} />
              <div className='initiatives-item-content'>
                <div className='initiatives-item-title'>
                  BONNEVAUX CENTRE FOR PEACE, France
                </div>
                <div className='initiatives-item-text'>
                  Bonnevaux Centre for Peace is an international meditation and retreat centre of the World Community for Christian Meditation. Via meditation and personal transformation, Bonnevaux fosters social transformation and serves peace and unity among all traditions through dialogue, silence and friendship.
                </div>
                <div className='initiatives-item-btn' onClick={() => window.open('https://bonnevauxwccm.org/')}>
                  {fm('Learn more')}
                </div>
              </div>
            </div>


          </div>

          {
            isMobile &&           <div className='mobile-initiatives-items'>

              <div className='initiatives-item-mobile initiatives-item-mobile2' style={{background: '#000000'}}>
                <div>
                  <img src={isMobile ? imgs['Group (8).svg']: imgs['Group 2965 (2).svg']} />
                </div>
                <div className='initiatives-item-content-mobile'>
                  <div className='initiatives-item-title-mobile'>
                    BONNEVAUX CENTRE FOR PEACE, France
                  </div>
                  <div className='initiatives-item-btn-mobile' onClick={() => window.open('https://bonnevauxwccm.org/')}>
                    <img src={imgs['arrow-down-right-svgrepo-com 1.svg']} />
                  </div>
                </div>
              </div>
            </div>
          }

          <div className='initiatives-current initiatives-pervious'>previous Projects</div>

          <div className='descktop-previous'>

            <div className='initiatives-item'>
              <img src={isMobile ? imgs['Group 2957.png']: imgs['Group 2982.svg']} />
              <div className='initiatives-item-content'>
                <div className='initiatives-item-title'>
                  INNER DISCOVERY FOR IMPACT, Singapore
                </div>
                <div className='initiatives-item-text'>
                  The Inner Discovery for Impact is a community or circle of purpose-led individuals who will convene regularly to share, exchange and co-learn together. This is a journey of reflection and authentic sharing to deepen our insights of this new emerging field, from a place of humility, authenticity and impact.
                </div>
                <div className='initiatives-item-btn' onClick={() => window.open('https://www.innerleadership.global/')}>
                  {fm('Learn more')}
                </div>
              </div>
            </div>


            <div className='initiatives-item'>
              <img src={ isMobile ? imgs['Group 2958.png']: imgs['Group 2983.svg']} />
              <div className='initiatives-item-content'>
                <div className='initiatives-item-title'>
                  MYANMAR JESUIT MISSION, Myanmar
                </div>
                <div className='initiatives-item-text'>
                  Our contribution supports two projects:
                  <ul>
                    <li>
                      St Aloysius Gonzaga Institute of Higher Studies (SAG) in Taunggyi: English language institute (advanced English studies, humanities, math, science, and social sciences)
                    </li>
                    <li>
                      Myanmar Leadership Institute (MLI) in Yangon: Purpose is to form emerging leaders in the fields of education, business, and civil society
                    </li>
                  </ul>
                </div>
                <div className='initiatives-item-btn' onClick={() => window.open('https://jesuitmission.org.au/')}>
                  {fm('Learn more')}
                </div>
              </div>
            </div>

            <div className='initiatives-item'>
              <img src={isMobile ? imgs['Group 2959.png']: imgs['Group 2984.svg']} />
              <div className='initiatives-item-content'>
                <div className='initiatives-item-title'>
                  INTERNATIONAL ZEN ASSOCIATION, Europe
                </div>
                <div className='initiatives-item-text'>
                  The International Zen Association is the oldest and largest Zen association in Europe, with several hundred places of practice (temples, Zen centres, dojos and groups) and a mother temple, La Gendronnière, in France.
                </div>
                <div className='initiatives-item-btn' onClick={() => window.open('https://www.zen-azi.org/en')}>
                  {fm('Learn more')}
                </div>
              </div>
            </div>
          </div>

          {
            isMobile &&           <div className='mobile-initiatives-items'>

              <div className='initiatives-item-mobile initiatives-item-mobile2' style={{background: '#354402'}}>
                <div>
                  <img src={isMobile ? imgs['image 2254.svg']: imgs['Group 2965 (2).svg']} />
                </div>
                <div className='initiatives-item-content-mobile'>
                  <div className='initiatives-item-title-mobile'>
                    INNER DISCOVERY FOR IMPACT, Singapore
                  </div>
                  <div className='initiatives-item-btn-mobile' onClick={() => window.open('https://www.innerleadership.global/')}>
                    <img src={imgs['arrow-down-right-svgrepo-com 1.svg']} />
                  </div>
                </div>
              </div>


              <div className='initiatives-item-mobile initiatives-item-mobile2' style={{background: '#E2664E'}}>
                  <div>
                    <img src={isMobile ? imgs['jesuit 2.svg']: imgs['Group 2965 (2).svg']} />
                  </div>
                  <div className='initiatives-item-content-mobile'>
                    <div className='initiatives-item-title-mobile'>
                      MYANMAR JESUIT MISSION, Myanmar
                    </div>
                    <div className='initiatives-item-btn-mobile' onClick={() => window.open('https://jesuitmission.org.au/')}>
                      <img src={imgs['arrow-down-right-svgrepo-com 1.svg']} />
                    </div>
                  </div>
                </div>

              <div className='initiatives-item-mobile initiatives-item-mobile2' style={{background: '#C42032'}}>
                <div>
                  <img src={isMobile ? imgs['Zen.svg']: imgs['Group 2965 (2).svg']} />
                </div>
                <div className='initiatives-item-content-mobile'>
                  <div className='initiatives-item-title-mobile'>
                    INTERNATIONAL ZEN ASSOCIATION, Europe
                  </div>
                  <div className='initiatives-item-btn-mobile' onClick={() => window.open('https://www.zen-azi.org/en')}>
                    <img src={imgs['arrow-down-right-svgrepo-com 1.svg']} />
                  </div>
                </div>
              </div>
            </div>
          }
          {/*<div className='view-more' onClick={() => setViewMoreInner(i => !i)}>*/}
          {/*  { viewMoreInner ? fm('Show less'): fm('View more')}*/}
          {/*  <img src={viewMoreInner ? imgs['Arrow 3 (1).svg']: imgs['Arrow 3.svg']} />*/}
          {/*</div>*/}
        </div>


        <div className='talk-panel'>

          <div>
            {fm('“Our journey is about being more deeply involved in life and yet less attached to it.”')}
          </div>

          <div> –Ram Dass</div>
        </div>
      </Container>
    );
  }

  if (pathName === '/systems-change') {
    return (
      <Container>

        <div className='top-panel top-panel3'>
          <div>
            <div className='top-title'>{fm('SYSTEMS CHANGE')}</div>
            <div className='top-text'>
              {fm('Systems change is about addressing the root causes of social problems, which are often intractable and embedded in networks of cause and effect. It is an intentional process designed to fundamentally alter the components and structures that cause the system to behave in a certain way.')}
            </div>
          </div>
        </div>

        <div className='our-app-title'>{fm('OUR APPROACH')}</div>

        <div className='mobile-top'>
          <div className='mobile-top-panel mobile-top-panel31'>
            {fm('SYSTEMS CHANGE')}
          </div>
          {/*<div className='mobile-top-text' >*/}
          {/*  {fm('Following organizations like Ashoka and Acumen, we believe that the most powerful force for good is a systems-changing idea in the hands of a formidable social entrepreneur/changemaker. True systems change is the result of scaling social impact rather than focusing on growing a single organisation; it is the result of letting go of control of an idea, and enabling and encouraging others to join in and implement change.\n')}*/}
          {/*  /!*Following organizations like Ashoka and Acumen, we believe that the most powerful force for good is a systems-changing idea in the hands of a formidable social entrepreneur/changemaker. True systems change is the result of <span>scaling social</span> impact rather than focusing on growing a single organisation; it is the result of <span>letting go of control of an idea</span>, and enabling and encouraging others to join in and implement change.*!/*/}
          {/*  <p></p>*/}
          {/*  {fm('Our family supports organizations, social entrepreneurs and changemakers using a Systems Change mindset to address our world’s most pressing issues. Here are some resources to learn more about Systems Change:')}*/}
          {/*</div>*/}
        </div>

        {
          isMobile && <>
            <div className='our-app-title-mobile'>{fm('OUR APPROACH')}</div>
            <div className='mobile-tab-content'>
              <img className='mobile-tab-content-img' src={imgs['hermes-rivera-R1_ibA4oXiI-unsplash 3 (1).png']} />
              <div className='mobile-tab-content-title'>
                <img  width={20} src={imgs['puzzle (1).svg']} />
                <div>{fm('SYSTEMS CHANGE')}</div>
              </div>

              <div className='mobile-tab-content-content'>
                Following organizations like Ashoka and Acumen, we believe that the most powerful force for good is a systems-changing idea in the hands of a formidable social entrepreneur/changemaker. True systems change is the result of <span>scaling social impact</span> rather than focusing on growing a single organisation; it is the result of <span>letting go of control of an idea</span>, and enabling and encouraging others to join in and implement change.
              </div>
            </div>
          </>
        }

        <div className='tab-content tab-content-special'>
          <div className='tab-content-title'>
            <img src={imgs['puzzle (1).svg']} />
            <div>{fm('SYSTEMS CHANGE')}</div>
          </div>
          <div className='tab-content-content'>
            {
              locale === 'en' ? <>
                Following organizations like Ashoka and Acumen, we believe that the most powerful force for good is a systems-changing idea in the hands of a formidable social entrepreneur/changemaker. True systems change is the result of <span>scaling social impact</span> rather than focusing on growing a single organisation; it is the result of <span>letting go of control of an idea</span>, and enabling and encouraging others to join in and implement change.
              </>: <>
                Theo chân các tổ chức như Ashoka và Acumen, chúng tôi tin rằng động lực mạnh mẽ nhất cho điều tốt đẹp là một ý tưởng thay đổi hệ thống nằm trong tay một doanh nhân/người tạo ra thay đổi xã hội đáng gờm. Thay đổi hệ thống thực sự là kết quả của việc mở rộng quy mô tác động xã hội thay vì tập trung vào phát triển một tổ chức đơn lẻ; đó là kết quả của việc từ bỏ quyền kiểm soát một ý tưởng, đồng thời tạo điều kiện và khuyến khích những người khác tham gia và thực hiện thay đổi.
              </>
            }
          </div>
          <img src={imgs['Group 2986.png']} />
        </div>

        <div className='themes '>
          {/*<div className='themes-title display-desktop'>{fm('OUR APPROACH')}</div>*/}
          <div className='themes-text themes-text-special'>
            {/*Following organizations like Ashoka and Acumen, we believe that the most powerful force for good is a systems-changing idea in the hands of a formidable social entrepreneur/changemaker. True systems change is the result of <span>scaling social impact</span> rather than focusing on growing a single organisation; it is the result of <span>letting go of control of an idea</span>, and enabling and encouraging others to join in and implement change.*/}
            {/*<p></p>*/}

            {
              locale === 'en' ? <>
                Our family supports organizations, social entrepreneurs and changemakers using a Systems Change mindset to address our world’s most pressing issues. Here are some resources to learn more about Systems Change:
              </>: <>
                Gia đình chúng tôi hỗ trợ các tổ chức, doanh nhân xã hội và những người tạo ra thay đổi bằng cách sử dụng tư duy Thay đổi Hệ thống để giải quyết các vấn đề cấp bách nhất trên thế giới của chúng ta. Dưới đây là một số tài nguyên để tìm hiểu thêm về Thay đổi hệ thống:
              </>
            }
          </div>
          <div className='approach-items'>
            <div className='approach-item'>
              <div className='approach-item-by'>By Ashoka</div>
              <div className='approach-item-title'>
                An Introduction to Systems Change
              </div>
              <div className='approach-item-btn' onClick={() => window.open('https://www.ashoka.org/en-gb/systems-change')}><img src={imgs['arrow-down-right-svgrepo-com 13.svg']} /></div>
            </div>

            <div className='approach-item'>
              <div className='approach-item-by'>By CATALYST 2030</div>
              <div className='approach-item-title'>
                About Systems Change
              </div>
              <div className='approach-item-btn' onClick={() => window.open('https://catalyst2030.net/what-is-systems-change/#:~:text=Confronting%20root%20causes%20of%20issues,of%20diverse%20people%20and%20organisations.')}><img src={imgs['arrow-down-right-svgrepo-com 13.svg']} /></div>
            </div>

            <div className='approach-item'>
              <div className='approach-item-by'>By FSG</div>
              <div className='approach-item-title'>
                Systems Change is a Noun and a Verb
              </div>
              <div className='approach-item-btn' onClick={() => window.open('https://www.fsg.org/blog/systems-change-noun-and-verb/')}><img src={imgs['arrow-down-right-svgrepo-com 13.svg']} /></div>
            </div>

            <div className='approach-item'>
              <div className='approach-item-by'>By Skoll Foundation</div>
              <div className='approach-item-title'>
                Centered Self: Systems Change From The Inside Out
              </div>
              <div className='approach-item-btn'><img src={imgs['arrow-down-right-svgrepo-com 13.svg']} /></div>
            </div>
          </div>
          <div className='themes-title'>{fm('OUR VISION')}</div>
          <div className='themes-text'>
            {
              locale === 'en' ? <>
                We are living in a time of <span>unprecedented global challenges.</span>
                <p></p>
                Our Vision is to develop a hub for social impact and philanthropy in Asia. We will want this center to support a new generation of leaders and changemakers to address the regions most critical challenges.
                <p></p>
                We believe in working with organizations and leaders on the ground to help scale solutions and build collaboratives on topical areas. This platform will cover the following areas: research & knowledge, convening & collaboration, leadership development and developing a patient capital fund.
              </> : <>
                Chúng ta đang sống trong thời kỳ có những thách thức toàn cầu chưa từng có. Tầm nhìn của chúng tôi là phát triển một trung tâm tác động xã hội và hoạt động từ thiện ở Châu Á. Chúng tôi muốn trung tâm này hỗ trợ một thế hệ lãnh đạo mới và những người tạo ra sự thay đổi để giải quyết những thách thức quan trọng nhất của khu vực. Chúng tôi tin tưởng vào việc hợp tác với các tổ chức và nhà lãnh đạo trên thực tế để giúp mở rộng các giải pháp và xây dựng mối quan hệ hợp tác trong các lĩnh vực chuyên đề. Nền tảng này sẽ bao gồm các lĩnh vực sau: nghiên cứu & kiến ​​thức, triệu tập và hợp tác, phát triển khả năng lãnh đạo và phát triển quỹ vốn bệnh nhân.

              </>
            }

          </div>
        </div>

        <div className='initiatives'>
          <div className='initiatives-title'>{fm('SYSTEMS CHANGE INITIATIVES')}</div>
          <div className='initiatives-current'>{fm('Current Projects')}</div>
          <div className='initiatives-text'>{fm('To realize this aim, here are the current organizations and projects we support:')}</div>
          <div className='initiatives-items'>

            <div className='initiatives-item'>
              <img src={isMobile ? imgs['Group 2960.png']: imgs['Group 2987.svg']} />
              <div className='initiatives-item-content'>
                <div className='initiatives-item-title'>
                  ASHOKA, Global
                </div>
                <div className='initiatives-item-text'>
                  Ashoka, founded in 1980, identifies and supports the world’s leading social entrepreneurs, learns from the patterns in their innovations, and mobilizes a global community that embraces these new frameworks to build an “Everyone a Changemaker World.”
                  <p></p>
                  The Nguyen-Phuong Family Endowment, established in 2014, is dedicated to supporting social entrepreneurs who operate in emerging markets – a permanent symbol of the family’s keen commitment to social services in the developing world.
                </div>
                <div className='initiatives-item-btn' onClick={() => window.open('https://www.ashoka.org/en/ashokas-endowments')}>
                  {fm('Learn more')}
                </div>
              </div>
            </div>

            <div className='initiatives-item'>
              <img src={isMobile? imgs['Group 2961.png']: imgs['Group 2988.svg']} />
              <div className='initiatives-item-content'>
                <div className='initiatives-item-title'>
                  ACUMEN, Global
                </div>
                <div className='initiatives-item-text'>
                  Acumen’s mission, founded in 2001, is to change the way the world tackles poverty by using the power of entrepreneurship to build a world where everyone has the opportunity to live with dignity. Acumen invests patient, long-term capital in innovative and sustainable startups that deliver critical goods and services to the world’s poor. Acumen also works to build a global community of emerging leaders that believe in creating a more inclusive world through the tools of both business and philanthropy.
                </div>
                <div className='initiatives-item-btn' onClick={() => window.open('https://acumen.org/')}>
                  {fm('Learn more')}
                </div>
              </div>
            </div>

            <div className='initiatives-item'>
              <img src={isMobile ? imgs['Group 2962.png']: imgs['Group 2989.svg']} />
              <div className='initiatives-item-content'>
                <div className='initiatives-item-title'>
                  ENDEAVOR, Global
                </div>
                <div className='initiatives-item-text'>
                  Endeavor (founded in 1997) is the world’s leading community of high-impact entrepreneurs. Its mission is to unlock the transformational power of entrepreneurship by selecting, supporting, and investing in the world’s top founders. Endeavor’s unique entrepreneur-first model and network of trust provide a platform for founders to dream big, scale up, and pay it forward to the next generation of entrepreneurs.
                </div>
                <div className='initiatives-item-btn' onClick={() => window.open('https://endeavor.org/network')}>
                  {fm('Learn more')}
                </div>
              </div>
            </div>

          </div>

          {
            isMobile &&           <div className='mobile-initiatives-items'>

              <div className='initiatives-item-mobile initiatives-item-mobile2' style={{background: '#F36523'}}>
                <div>
                  <img src={isMobile ? imgs['image 2249.svg']: imgs['Group 2965 (2).svg']} />
                </div>
                <div className='initiatives-item-content-mobile'>
                  <div className='initiatives-item-title-mobile'>
                    ASHOKA, Global
                  </div>
                  <div className='initiatives-item-btn-mobile' onClick={() => window.open('https://www.ashoka.org/en/ashokas-endowments')}>
                    <img src={imgs['arrow-down-right-svgrepo-com 1.svg']} />
                  </div>
                </div>
              </div>


              <div className='initiatives-item-mobile initiatives-item-mobile2' style={{background: '#B5208E'}}>
                <div>
                  <img src={isMobile ? imgs['image 2248.svg']: imgs['Group 2965 (2).svg']} />
                </div>
                <div className='initiatives-item-content-mobile'>
                  <div className='initiatives-item-title-mobile'>
                    ACUMEN, Global
                  </div>
                  <div className='initiatives-item-btn-mobile' onClick={() => window.open('https://acumen.org/')}>
                    <img src={imgs['arrow-down-right-svgrepo-com 1.svg']} />
                  </div>
                </div>
              </div>

              <div className='initiatives-item-mobile initiatives-item-mobile2' style={{background: '#19AB86'}}>
                <div>
                  <img src={isMobile ? imgs['image 2250.svg']: imgs['Group 2965 (2).svg']} />
                </div>
                <div className='initiatives-item-content-mobile'>
                  <div className='initiatives-item-title-mobile'>
                    ENDEAVOR, Global
                  </div>
                  <div className='initiatives-item-btn-mobile' onClick={() => window.open('https://endeavor.org/network')}>
                    <img src={imgs['arrow-down-right-svgrepo-com 1.svg']} />
                  </div>
                </div>
              </div>
            </div>
          }
        </div>

        <div className='talk-panel'>

          <div>
            {fm('“Social entrepreneurs are not content just to give a fish or teach how to fish. They will not rest until they have revolutionized the fishing industry.”')}
          </div>

          <div>– Bill Drayton</div>
        </div>

      </Container>
    );
  }

}

const Container = styled.div`
  
  
  .es-themes-tab {
    @media (max-width: 991px) {
      text-align: center;
    }
  }
  
  .our-app-title-mobile {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #000000;
    width: 85%;
    margin: 5px auto;
    margin-bottom: -30px;
  }
  
  .our-app-title {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    color: #000000;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: -70px;
    @media (max-width: 991px) {
      display: none;
    }
  }
  
  .mobile-themes-tab-content {
    width: 90%;
    margin: 0 auto;
    padding-bottom: 10px;
    & > div:nth-child(1) {
      font-family: 'Bebas Neue';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #000000;
      margin-top: 10px;
      margin-bottom: 5px;
    }

    & > div:nth-child(2) {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      color: #000000;
      
      ul {
        padding-left: 2px;
        list-style: none;
      }
    }
  }
  
  .mobile-middle-panel-item {
    background: #F3F7E6;
    border-radius: 14px;
    margin-bottom: 15px;
    img {
      width: 100%;
    }
  }
  
  .mobile-inner-middle-panel {
    width: 85%;
    margin: 10px auto;
  }
  
  .inner-middle-panel {
    @media (max-width: 991px) {
      display: none;
    }
  }
  
  .following-content-text {
    @media (max-width: 991px) {
      display: none;
    }
  }
  
  .mobile-tab-content-title-img {
    @media (max-width: 991px) {
      height: 30px;
    }
  }
  
  
  .initiatives-item-text-mobile {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    color: #FFFFFF;
  }
  
  .initiatives-item-btn-mobile {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }
  
  .initiatives-item-title-mobile {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    color: #FFFFFF;
  }
  
  .initiatives-item-mobile {
    display: flex;
    column-gap: 15px;
    background: #00186D;
    border-radius: 14px;
    padding: 10px 10px;
    position: relative;
    margin-bottom: 10px;
    min-height: 82px;
    
    & > div:nth-child(1) {
      min-width: 107px;
      display: flex;
      justify-content: center;
    }
  }
  
  .initiatives-item-mobile2 {
    align-items: center;
    background: #354402;
  }
  
  .initiatives-item-mobile3 {
    align-items: center;
  }
  
  .initiatives-item-mobile5 {
    background: #00B5CB;
  }

  .initiatives-item-mobile6 {
    background: #F55235;
  }
  
  .descktop-previous {
    display: block;

    @media (max-width: 991px) {
      display: none;
    }
  }
  
  .mobile-tab-content-img {
    position: absolute;
    top: -40px;
    right: 0;
  }
  
  .mobile-tab-view-more {
    margin-top: 8px;
    text-align: center;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    color: #828282;



    & > img {
      margin-left: 3px;
      height: 5px;
    }

  }
  
  .mobile-tab-content-content {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    color: #000000;
    span {
      font-weight: bold;
    }
  }
  
  .mobile-tab-content {
    background: #F3F7E6;
    border-radius: 14px;
    width: 90%;
    margin: 10px auto;
    padding: 20px 15px;
    position: relative;
    margin-top: 70px;
  }
  
  .mobile-tab-content-title {
    //font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #354402;
    margin-top: 10px;
    margin-bottom: 15px;
  }
  
  .themes-text-special {
    margin-top: 160px;
    margin-bottom: 30px !important;
    @media (max-width: 991px) {
      margin: 20px auto !important;
    }
  }
  
  .middle-panel-item {
    display: flex;
    & > div {
      flex: 0 0 50%;
      background: #F3F7E6;
      & > img {
        width: 100%;
        object-fit: cover;
  

        &.middle-panel-item-top-img {
          width: 105%;
          margin-right: -5%;
        }

        &.middle-panel-item-bottom-img {
          width: 105%;
          margin-left: -5%;
        }
      }


      
      &.middle-panel-item-right {
        background: #F3F7E6;
        border-radius: 40px 0px 0px 40px;
        padding: 40px 80px;
        position: relative;
        z-index: 1;
        display: flex;
        flex-flow: column;
        //align-items: center;
        justify-content: center;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
      }

      &.middle-panel-item-left {
        background: #F3F7E6;
        border-radius: 0px 40px 40px 0px;
        padding: 40px 80px;
        position: relative;
        z-index: 1;
        display: flex;
        flex-flow: column;
        //align-items: center;
        justify-content: center;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  
  .themes-text-ul {
    list-style: none;
    padding-left: 10px;
    @media (max-width: 991px) {
      padding-left: 0;
    }
  }

  .following-content {
    display: flex;
    flex-flow: column;
    //justify-content: space-between;
    //align-items: center;
    //width: 400px;
    width: 100%;
    //height: 210px;

    background: #00738E;
    border-radius: 20px;
    padding: 20px;

    @media (max-width: 991px) {
      flex-flow: column;
      align-items: flex-start;
      width: 158px;
    }

    & > div:nth-child(1) {
      height: 73px;
      display: flex;
      align-items: center;
      margin-bottom: 14px;
      & > img {
        //height: 100%;
      }
      @media (max-width: 991px) {
        //width: 165px;
        //justify-content: center;
        width: 100%;
        height: auto;
        & > img {
          height: 40px;
          &.special {
            //height: auto;
          }
          &.following-content-img5 {
            height: 26px;
          }

          &.following-content-img6 {
            height: 20px;
          }
        }
        margin-bottom: 15px;
      }

    }
    & > div:nth-child(2) {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #FFFFFF;
      width: 95%;
      flex: 1;

      position: relative;

      & > span {
        //font-weight: 700;
        //text-decoration-line: underline;
        //color: #11224D;
        position: absolute;
        cursor: pointer;
        bottom: -10px;
        right: -5%;
      }
      @media (max-width: 991px) {
        font-size: 14px;
        line-height: 19px;
        width: 100%;
      }
    }
  }

  .following-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 30px;
    //width: 85%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 70px;
    @media (max-width: 991px) {
      grid-template-columns: repeat(2, 1fr);
      margin: 30px auto;
      grid-column-gap: 10px;
      margin-top: 10px;
    }
  }
  
  .initiatives-items {
    margin-top: 70px;

    @media (max-width: 991px) {
      display: none;
    }
  }
  
  .tab-content-content {
    margin-top: 20px;
    width: 60%;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #000000;
    
    & > span {
      font-weight: bold;
    }
  }
  
  .tab-content-title {
    display: flex;
    align-items: center;
    column-gap: 15px;
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    color: #354402;
  }
  
  .tab-content {
    background: #F3F7E6;
    border-radius: 25px;
    padding: 40px;
    width: 85%;
    margin: 0 auto;
    margin-top: 100px;
    position: relative;
    @media (max-width: 991px) {
      display: none;
    }
    & > img {
      position: absolute;
      top: -30px;
      right: -30px;
    }
  }
  
  .tab-content-special {
    padding-bottom: 100px;
  }
  
  .new-themes-tab {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 40px;
    @media (max-width: 991px) {
      margin-top: 10px;
      width: 117%;
      margin-left: -8.5%;
    }
    & > div {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 20px solid #BBC1A2;
      color: #BBC1A2;
      padding-top: 20px;
      font-family: 'Bebas Neue';
      font-weight: 400;
      font-size: 36px;
      color: #BBC1A2;

      @media (max-width: 991px) {
        border-top-width: 4px;
        font-size: 16px;
        padding-top: 8px;
      }


      &.selected {
        color: #354402;
        border-color: #354402;
      }
    }
  }
  
  .approach-item-btn {
    cursor: pointer;
    color: #354402;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    position: absolute;
    bottom: 10px;
    right: 10px;
    @media (max-width: 991px) {
      font-size: 11px;
    }
  }
  .approach-item-title {
    color: #000;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 18px;
    //border-bottom: 2px solid #000;
    margin-bottom: 18px;
    @media (max-width: 991px) {
      font-size: 11px;
      margin-bottom: 5px;
      padding: 0;
      border: none;
    }
  }
  .approach-item-by {
    color: #354402;
    font-family: 'Open Sans';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 10px;
    @media (max-width: 991px) {
      font-size: 9px;
      margin-bottom: 3px;
    }
  }
  .approach-item {
    background: #F3F7E6;
    width: 310px;
    padding: 30px 12px 30px 27px;
    position: relative;
    @media (max-width: 991px) {
      width: 100%;
      padding: 5px 10px;
      margin-bottom: 11px;
      border-left: 10px solid #354402;
    }
  }
  .approach-items {
    margin: 40px 0;
    display: flex;
    justify-content: center;
    column-gap: 40px;
    @media (max-width: 991px) {
      flex-flow: column;
      margin: 10px 0;
    }
 
  }
  .initiatives-item-ul {
    color: #000;
    font-family: 'Open Sans';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 25px;
    span {
      font-weight: 600;
    }
  }
  .view-more {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    color: #354402;
    font-family: 'Open Sans';
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 10px 0;
    @media (max-width: 991px) {
      display: none;
    }
    & > img {
      margin-left: 10px;
      margin-top: 5px;
    }
  }
  .initiatives-item-btn {
    margin-left: auto;
    color: #FFF;
    font-family: 'Open Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 170px;
    height: 50px;
    background: #354402;
    border-radius: 5px;
    transform: translateY(30px);
    @media (max-width: 991px) {
      width: 118px;
      height: 23px;
      font-size: 12px;
      border-radius: 3px;
    }
  }
  .initiatives-item-text {
    color: #000;
    font-family: 'Open Sans';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 18px;
    ul {
      padding-left: 30px;
    }
    @media (max-width: 991px) {
      display: none;
    }
  }
  .initiatives-item-title {
    color: #11224D;
    font-family: 'Open Sans';
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 13px;
    @media (max-width: 991px) {
      font-size: 14px;
      min-height: 37px;
    }
  }
  .initiatives-item-content {
    border-radius: 14px;
    background: #F2F2F7;
    padding: 12px 40px;
    flex: 1;
    @media (max-width: 991px) {
      padding: 8px 12px;
    }
  }
  .initiatives-item {
    display: flex;
    margin-bottom: 80px;
    background: #F2F2F7;
    border-top-left-radius: 13px;
    border-bottom-left-radius: 13px;
    margin-left: 30px;
    & > img {
      height: 100%;
      width: 258px;
      object-fit: cover;
      transform: translate(-50px, -30px);
    }
    @media (max-width: 991px) {
      & > img {
        min-width: 120px;
        width: 120px;
      }
    }
  }
  .initiatives-text {
    color: #000;
    font-family: 'Open Sans';
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 22px;
    @media (max-width: 991px) {
      display: none;
    }
  }
  .initiatives-current {
    margin: 13px 0;
    color: #354402;
    font-family: 'Bebas Neue';
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 13px;
    border-left: 3px solid #354402;
    @media (max-width: 991px) {
      padding-left: 5px;
      font-size: 16px;
      margin-top: 0;
      margin-bottom: 10px;
    }
  }
  
  .initiatives-pervious {
    color: #828282;
    border-color: #828282;
    margin-bottom: 50px;

    @media (max-width: 991px) {
      margin-bottom: 10px;
      margin-top: 20px;
    }
  }
  
  .initiatives-title {
    color: #000;
    font-family: 'Bebas Neue';
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media (max-width: 991px) {
      font-size: 24px;
      margin-top: 20px;
    }
  }
  .initiatives {
    width: 85%;
    margin: 46px auto;
    @media (max-width: 991px) {
      margin: 0 auto 10px auto;
    }
  }
  
  .themes {
    width: 85%;
    margin: 7% auto 40px auto;
    @media (max-width: 991px) {
      margin: 20px auto 20px auto;
    }
    .themes-text {
      color: #000;
      font-family: 'Open Sans';
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 60px;
      span {
        font-weight: 700;
      }
      @media (max-width: 991px) {
        font-size: 14px;
        margin-bottom: 20px;
      }
    }
    .themes-title {
      color: #000;
      font-family: 'Bebas Neue';
      font-size: 48px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 5px;
      @media (max-width: 991px) {
        font-size: 24px;
        margin-bottom: 2px;
      }
    }
    .themes-tips {
      color: #000;
      font-family: 'Open Sans';
      font-size: 20px;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 11px;
      @media (max-width: 991px) {
        font-size: 11px;
      }
    }
    .themes-tabs-mobile {
      display: none;
      @media (max-width: 991px) {
        display: flex;
        justify-content: space-between;
        width: 118%;
        margin-left: -9%;
        .themes-tab {
          flex: 0 0 48%;
          color: #E5E5E5;
          font-family: 'Bebas Neue';
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-align: center;
          padding-bottom: 3px;
          border-bottom: 3px solid #E5E5E5;
          &.selected {
            color: #354402;
            border-color: #354402;
          }
        }
      }
    }
    .themes-text-mobile {
      display: none;
      @media (max-width: 991px) {
        display: block;
        margin: 0 auto;
        padding: 16px 0 0 0;
        color: #000;
        font-family: Open Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .themes-tabs {
      display: flex;
      justify-content: center;
      column-gap: 82px;
      @media (max-width: 991px) {
        display: none;
      }

      .themes-tab {
        flex: 0 0 45%;
        max-width: 540px;
        border-radius: 14px;
        cursor: pointer;
        display: flex;
        flex-flow: column;
        @media (max-width: 991px) {
          margin-bottom: 10px;
        }
        
        &.selected {
          .themes-tab-top {
            background: #354402;
          }
          .themes-tab-content {
            background: #F3F7E6;
            & > div:nth-child(1) {
              color: #354402;
            }
          }
        }
        
        .themes-tab-top {
          border-top-left-radius: 14px;
          border-top-right-radius: 14px;
          background: #828282;
          height: 10px;
        }
        .themes-tab-img {
          width: 100%;
          height: 200px;
          object-fit: cover;
          @media (max-width: 991px) {
            height: 120px;
          }
        }
        
      }
    }
  }

  .themes-tab-content {
    padding: 12px 30px;
    background: #F2F2F7;
    flex: 1;
    & > div:nth-child(1) {
      color: #000;
      font-family: 'Bebas Neue';
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 10px;
      @media (max-width: 991px) {
        font-size: 16px;
        margin-bottom: 5px;
      }
    }
    & > div:nth-child(2) {
      color: #000;
      font-family: 'Open Sans';
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      @media (max-width: 991px) {
        font-size: 10px;
      }
    }
    ul {
      padding-left: 30px;
      list-style: decimal;
    }
  }
  margin: 0 auto;

  .margin-bottom0 {
    margin-bottom: 0;
  }
  .fit-mobile-margin {
    margin-top: 180px;
    @media (max-width: 991px) {
      margin-top: 20px;
    }
  }
  .approach-panel {
    padding-bottom: 60px;
    @media (max-width: 991px) {
      padding-bottom: 30px;
    }
  }
  
  .panel-content1 {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    color: #000000;
    margin-bottom: 80px;
    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 20px;
    }
    & > div {
      margin-bottom: 20px;
      
      & > a {
        font-weight: 700;
        font-size: 22px;
        line-height: 30px;
        text-decoration-line: underline;

        color: #FB7E26;
        @media (max-width: 991px) {
          font-size: 14px;
          line-height: 19px;
        }
      }
    }
    span {
      font-weight: 700;
    }
  }

  .panel-content2 {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    color: #000000;
    @media (max-width: 991px) {
      font-size: 12px;
      line-height: 16px;
      width: 75%;
    }
    & > div {
      margin-bottom: 20px;
      @media (max-width: 991px) {
        margin-bottom: 10px;
      }
    }
  }

  .panel-title {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    background-image: url("${imgs['Group 1949.svg']}");
    background-size: contain;
    height: 83px;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 47px;
    @media (max-width: 991px) {
      height: 42px;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 20px;
    }
  }
  
  .header {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px 0 80px 0;
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;

    & > div {
      padding-bottom: 3px;
      border-bottom: 3px solid transparent;
      cursor: pointer;
      &.selected {
        border-color: #FB7E26;
      }
    }
  }
  
  
  .mobile-top {
    display: none;
    @media (max-width: 991px) {
      display: block;
    }
  }
  .mobile-top-panel {
    display: none;
    background-image: url("${imgs['Group 2963.png']}");
    background-size: cover;
    background-repeat: no-repeat;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: 157px;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #FFFFFF;
    @media (max-width: 991px) {
      display: flex;
    }
  }
  
  
  .top-text {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #FFFFFF;
    width: 80%;
    margin: 0 auto;
    text-align: left;
  }
  
  .toggle-types {
    width: 85%;
    margin: 46px auto 57px auto;
    display: flex;
    justify-content: center;
    margin-top: 180px;
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;

    color: #000000;

    @media (max-width: 991px) {
      font-size: 18px;
      line-height: 25px;
      margin: 27px auto 29px auto;
    }
    
    & > div {
      padding-bottom: 6px;
      border-bottom: 3px solid transparent;
      cursor: pointer;
      &:nth-of-type(1) {
        margin-right: 80px;
      }
      &:hover {
        color: #FB7E26;
      }
      &.selected {
        border-color: #FB7E26;
        color: #FB7E26;
      }
    }
  }
  
  .list {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .current-title {
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    color: #000000;
    margin-bottom: 51px;
    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 29px;
    }
  }
  
  .list-item {
    display: flex;
    margin-bottom: 80px;
    @media (max-width: 991px) {
      margin-bottom: 32px;
    }
  }
  
  .list-item-img {
    margin-right: 100px;
    width: 300px;
    height: 300px;
    @media (max-width: 991px) {
      width: 98px;
      height: 98px;
      margin-right: 12px;
    }
  }
  
  .list-item-title {
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    color: #11224D;
    margin-bottom: 20px;
    @media (max-width: 991px) {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 28px;
    }
  }
  
  .list-item-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 20px;
    @media (max-width: 991px) {
      display: none;
    }
  }
  
  .learn-more-btn {
    width: 200px;
    height: 54px;
    border: 1px solid #FB7E26;
    border-radius: 3px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #FB7E26;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    @media (max-width: 991px) {
      width: 118px;
      height: 27px;
      font-size: 14px;
      line-height: 19px;
    }
  }
  
  .list-item-ul {
    padding-left: 40px;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    @media (max-width: 991px) {
      display: none;
    }
  }
  
  .list-item-ul-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;

    color: #000000;
  }
  
  .top-panel1 {
    background-image: url("${imgs['Group 1980.png']}");
  }
  
  .mobile-top-panel1 {
    margin-top: -30px;
    background-image: url("${imgs['Group 2971.png']}");
  }
  
  .mobile-top-panel31 {
    margin-top: -30px;
    background-image: url("${imgs['Group 3009.png']}");
  }

  .top-panel2 {
    background-image: url("${imgs['Group 2980.png']}") !important;
  }
  
  .mobile-top-panel2 {
    background-image: url("${imgs['Group 1958.png']}");
  }
  
  .top-panel3 {
    background-image: url("${imgs['Group 2985.png']}") !important;
  }
  
  .mobile-top-panel3 {
    background-image: url("${imgs['Group 1959.png']}");
  }

  .talk-panel {

    margin-bottom: -40px;
    height: 392px;
    //width: 50%;
    //margin: 20px auto 80px auto;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    background-color: #354402;
    background-image: url("${imgs['Vector.svg']}");
    background-position: center center;
    background-repeat: no-repeat;
    color: white;

    border-radius: 40px 40px 0px 0px;


    @media (max-width: 991px) {
      background-size: 235px 235px;
      height: 268px;
      width: 100%;
      //margin: 12px auto 36px auto;
      font-size: 14px;
      line-height: 19px;
      position: relative;
      z-index: 2;
    }
    & > div:nth-child(1) {
      width: 50%;
      margin-bottom: 20px;
      @media (max-width: 991px) {
        width: 70%;
      }
    }
  }
  
  .mobile-top-text {
    padding: 10px 7.5%;
    color: #000;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    & > span {
      font-weight: 700;
    }
  }
  
  .display-mobile {
    display: none;
    @media (max-width: 991px) {
      display: block;
    }
  }
  
  .display-desktop {
    display: block;
    @media (max-width: 991px) {
      display: none;
    }
  }
  
  .inner-tabs {
    @media (max-width: 991px) {
      display: block !important;
    }
  }
  
  .initiatives-items-title {
    padding-left: 0;
    border-left: none;
    @media (max-width: 991px) {
      display: none;
    }
  }
  
  .themes-special {
    margin-top: 80px;
    @media (max-width: 991px) {
      margin-top: 10px;
    }
  }

  .top-panel {
    background-image: url("${imgs['Group 2963 (1).png']}");
    background-size: cover;
    background-repeat: no-repeat;
    height: 494px;
    display: flex;
    padding: 90px 0;
    justify-content: flex-end;
    @media (max-width: 991px) {
      display: none;
    }
    & > div {
      flex: 0 0 50%;
      & > div:nth-of-type(1) {
        font-weight: 700;
        font-size: 50px;
        line-height: 68px;
        color: #FFFFFF;
        width: 80%;
        margin: 0 auto;
        text-align: left;
        margin-bottom: 40px;
      }
    }
  }
  
`;

export default OurProjects;
