import React, { useState, useEffect, useRef } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Footer from './Footer';
import Header from "./Header";
import { useIntl } from 'react-intl';
import {message as Amessage} from 'antd';
import importAllImg from "../helpers/import-all-imgs";

const imgs = importAllImg(require.context('../img/npf', false, /\.(png|jpe?g|svg)$/));


function contactUs(params) {
  const url = `https://udty.tech/api/v3/npf`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params),
  };

  return fetch(url, requestOptions)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}


function ContactUs() {

  const { formatMessage } = useIntl();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [topic, setTopic] = useState('');

  const fm = id => formatMessage({id});

  const submit = e => {
    e.preventDefault();
    if (!name) return;
    if (!email) return
    const params = {name, email, message, topic};

    contactUs(params).then(res => {
      Amessage.success('success');
    }, rej => Amessage.error(JSON.stringify(rej)));
  };

  return (
    <Container>

      <div className='top'>
        <div className='title'>{fm('CONTACT US')}</div>
      </div>

      <div className='bottom'>
        <div className='left'>
          <div className='left-title'>{fm('GET IN TOUCH WITH US')}</div>
          <div className='left-des'>{fm('Have a project to share? Interested in building a better World with us?')}</div>

          <div className='icon-row'>
            <img src={imgs['Group 2994.svg']} />
            <span>Singapore</span>
          </div>

          <div className='icon-row'>
            <img src={imgs['Group 2995 (1).svg']} />
            <a href="mailto:hello@nguyenphuongfamily.org">
              hello@nguyenphuongfamily.org
            </a>
          </div>

          <div className='left-sub-title'>{fm('FOLLOW US')}</div>

            <div className='left-icons'>
              <img src={imgs['Group 2996.svg']} onClick={() => window.open('https://www.facebook.com/NguyenPhuongFamily')}/>
              <img src={imgs['Group 2998.svg']} onClick={() => window.open('https://medium.com/@familynguyenphuong')} />
              <img src={imgs['Group 2999.svg']} onClick={() => window.open('https://www.linkedin.com/company/77767320/')}/>
            </div>
        </div>

        <form className='right' onSubmit={submit}>
          <div className='content'>

            <div className='content-bottom'>
              <div className='label-text'>Your Name  <span>*</span></div>
              <input value={name} onChange={e => setName(e.target.value)} required className='input' placeholder={fm('Name (required)')}/>

              <div className='label-text'>Email Address <span>*</span></div>
              <input type="email"  onChange={e => setEmail(e.target.value)} required className='input' placeholder={fm('Email (required)')}/>

              <div className='label-text'>{fm('Topic')}</div>
              <input value={topic} onChange={e => setTopic(e.target.value)} required className='input' placeholder={fm('Topic')}/>

              <div className='label-text'>Your Message  <span>*</span></div>
              <textarea onChange={e => setMessage(e.target.value)}  className='textarea' placeholder={fm('Message')}/>

              <button type='submit' className='contact-us'>{fm('SUBMIT')}</button>
            </div>

          </div>
        </form>
      </div>


    </Container>
  )
}

const Container = styled.div`
  
  .label-text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #828282;
    margin-bottom: 5px;
    & > span {
      color: red;
    }
  }
  
  .right {
    margin-top: 40px;

    @media (max-width: 991px) {
      margin-top: 0;
    }
  }
  
  .left-des {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    color: #000000;
    margin-bottom: 15px;
    width: 80%;

    @media (max-width: 991px) {
      font-size: 14px;
      color: #000000;
      margin-bottom: 10px;
      width: 100%;
    }
  }
  
  .left-sub-title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #000000;
    margin-bottom: 10px;
    margin-top: 50px;
    @media (max-width: 991px) {
      display: none;
    }
  }
  
  .left-icons {
    display: flex;
    column-gap: 20px;
    @media (max-width: 991px) {
      display: none;
    }
    & > img {
      cursor: pointer;
    }
  }
  
  .icon-row {
    display: flex;
    align-items: center;
    column-gap: 20px;
    margin-bottom: 15px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #000000;

    @media (max-width: 991px) {
      & > img {
        width: 37px;
      }
      font-size: 11px;
    }
    
    & > a {
      color: #000000;
      text-decoration: underline;
    }
  }
  
  .left-title {
    font-family: 'Bebas Neue';
    font-weight: 400;
    font-size: 36px;
    color: #354402;
    margin-bottom: 15px;

    @media (max-width: 991px) {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
  .left {
    
  }
  .bottom {
    display: flex;
    width: 90%;
    margin: 0 auto;
    padding: 20px 0;

    @media (max-width: 991px) {
      flex-flow: column;
      width: 85%;
    }
  }
  .content-bottom {
    //background: #F3F7E6;
    //padding: 50px 7.5%;
    @media (max-width: 991px) {
      padding-top: 0;
    }
  }
  .icons {
    margin-bottom: 60px;
    @media (max-width: 991px) {
      margin-bottom: 30px;
    }
    & > img, & > a {
      cursor: pointer;
      @media (max-width: 991px) {
        height: 42px;
        & > img {
          height: 42px;
        }
      }
    }
  }

  .icons > img:nth-child(3) {
    margin-right: 30px;
    @media (max-width: 991px) {
      margin-right: 15px;
    }
  }

  .icons > img:nth-child(2) {
    margin: 0 30px;
    @media (max-width: 991px) {
      margin: 0 15px;
    }
  }
  .content-top {
    display: flex;  
    justify-content: space-between;
    background: white;
    padding-left: 7.5%;
    padding-right: 7.5%;
    @media (max-width: 991px) {
      background: #F3F7E6;
    }
  }
  
  .content {
    //background: #FFFBF8;
    //padding: 120px 0 0 0;
    @media (max-width: 991px) {
      //padding: 28px 0 0 0;
      //background: #F3F7E6;
    }
  }
  
  .content-title {
    font-family: 'Bebas Neue';
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    color: #11224D;
    margin-bottom: 30px;
    position: relative;
    @media (max-width: 991px) {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 23px;
    }
  }
  
  .title-circle {
    width: 100px;
    height: 100px;
    position: absolute;
    top: -50px;
    left: -50px;
    border-radius: 50%;
    background: rgba(251, 126, 38, 0.3);
    @media (max-width: 991px) {
      width: 25px;
      height: 25px;
      top: -10px;
      left: -10px;
    }
  }
  
  .content-des {
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 42px;
    color: #828282;
    width: 80%;
    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 32px;
      width: 100%;
      color: #000;
    }
  }
  
  .input {
    width: 646px;
    height: 54px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #828282;
    padding-left: 18px;
    margin-bottom: 24px;
    background: #FFFFFF;
    border-radius: 3px;
    border: none;
    //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;

    background: #F3F7E6;


    @media (max-width: 991px) {
      width: 100%;
      height: 44px;
      font-size: 16px;
      line-height: 22px;
      padding-left: 16px;
    }
    
    &:focus {
      outline: none;
    }
  }
  
  .textarea {
    width: 646px;
    height: 250px;
    background: #FFFFFF;
    border-radius: 3px;
    padding: 36px 17px 0 18px;
    border: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #828282;
    background: #F3F7E6;
    //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;

    @media (max-width: 991px) {
      width: 100%;
      height: 208px;
      font-size: 16px;
      line-height: 22px;
      padding-left: 16px;
      padding-top: 16px;
    }
    &:focus {
      outline: none;
    }
  }
  
  .contact-us {
    background: #354402;
    border-radius: 3px;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    cursor: pointer;
    margin-top: 38px;
    border: none;
    outline: none;
    width: 165px;
    height: 44px;
    text-transform: uppercase;

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    @media (max-width: 991px) {
      margin-top: 29px;
      margin-left: auto;
      margin-right: auto;
    }
  }


  
  .content-top-left {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    @media (max-width: 991px) {
      align-items: center;
    }
  }
  
  .content-top-right {
    display: flex;
    flex-flow: column;
    align-items: center;
    @media (max-width: 991px) {
      display: none;
    }
  }

  .panel-title {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 43px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #000000;
    // background-image: url("${imgs['Group 2895 (1).svg']}");
    background-size: contain;
    height: 83px;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 30px;
    @media (max-width: 991px) {
      height: 42px;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 20px;
    }
  }

  .top {
    height: 384px;
    background-image: url('${imgs['Group 2993.png']}');
    background-size: cover;
    padding-left: 7.5%;
    padding-top: 150px;
    @media (max-width: 991px) {
      height: 211px;
      padding-top: 100px;
      margin-top: -50px;
      background-image: url("${imgs['Group 3005.png']}");
    }
  }

  .title {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-image: url("${imgs['Group 2952.svg']}");
    background-size: contain;
    height: 103px;
    background-repeat: no-repeat;
    //padding-left: 15px;
    margin-bottom: 15px;

    font-size: 50px;
    padding-left: 5px;
    color: #FFFFFF;


    @media (max-width: 991px) {
      height: 42px;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 20px;
      //justify-content: center;
      //background-position: center;
    }
  }
`;


export default ContactUs;
