import React, { useState, useEffect, useRef } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import importAllImg from "../helpers/import-all-imgs";
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";


const imgs = importAllImg(require.context('../img/npf', false, /\.(png|jpe?g|svg)$/));

const Container = styled.div`
    padding: 36px;
    background: #354402;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    position: relative;

  border-radius: 40px 40px 0px 0px;



  @media (max-width: 991px) {
    //background: white;
  }

  .bottom-img {
    padding-bottom: 20px;
    //border-bottom: 1px solid #828282;
    @media (max-width: 991px) {
      & > img.first {
        width: 30px;
        margin-top: 10px;
      }
    }
  }

  .bottom-icons {
    padding: 17px 0;
    & > img {
      cursor: pointer;
    }
    & > img:nth-child(2) {
      margin: 0 33px;
    }
    & > img:nth-child(3) {
      margin-right: 33px;
    }
  }

  .bottom-text {
    font-family: 'Open Sans';
    //font-style: italic;
    font-weight: 400;
    color: #FFFFFF;
    margin-top: 10px;
    font-size: 12px;
  }
  
  .bottom-text-special {
    cursor: pointer;
  }

  .fixed-icons {
    position: absolute;
    right: 74px;
    bottom: 52px;
    @media (max-width: 991px) {
      display: none;
    }
    & > img {
      cursor: pointer;
      &:nth-of-type(1) {
        margin-right: 46px;
      }
    }
  }
  
  .connect-us {
    color: #FFF;
    text-align: center;
    font-family: 'Bebas Neue';
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

function Footer({toggleLang}) {

  const navigate = useNavigate();

  const { formatMessage } = useIntl();

  const fm = id => formatMessage({id});
  return (
    <Container>
      {/*<div className='fixed-icons'>*/}
      {/*  <img onClick={toggleLang} src={imgs['language.svg']}/>*/}
      {/*  <img onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})} src={imgs['arrow-up.svg']}/>*/}
      {/*</div>*/}
      <div className='bottom-img'><img className='first' src={imgs['Group 2898 (2).svg']} /></div>

      <div className='bottom-img'>
        <img src={imgs['NGUYỄN-PHƯƠNG FAMILY.svg']}/>
      </div>
      <div className='connect-us'>{fm('CONNECT WITH US')}</div>

      <div className='bottom-icons'>
        <img src={imgs['Group 2901 (1).svg']} onClick={() => window.open('https://www.facebook.com/NguyenPhuongFamily')}/>
        <img src={imgs['Group 2899 (1).svg']} onClick={() => window.open('https://medium.com/@familynguyenphuong')} />
        <img src={imgs['Group 2900 (1).svg']} onClick={() => window.open('https://www.linkedin.com/company/77767320/')}/>
        <a href="mailto:familynguyenphuong@gmail.com">
          <img src={imgs['Group 2902 (1).svg']} alt="Email Link" />
        </a>
      </div>
      <div className='bottom-text'>
        {fm('Nguyễn-Phương Family © 2023. All rights reserved.')}
      </div>
      <div className='bottom-text bottom-text-special' onClick={() => navigate('/privacy-policy')}>
        {fm('Privacy Policy')}
      </div>
    </Container>
  )
}


export default Footer;
